<template>
    <AutocompleteBase
        v-model="selected"
        v-bind="$attrs"
        :items="fulfillmentTypeList"
        itemText="friendly"
        placeholder="Fulfillment Type"
        outlined
        v-on="$listeners"
    />
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Components
import AutocompleteBase from '@/components/base/dropdowns/AutocompleteBase';

export default {
    name: 'DropdownFulfillmentTypes',
    components: {
        AutocompleteBase,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters( {
            fulfillmentTypeList: 'GlobalResources/fulfillmentTypeList',
        } ),
        selected: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
};
</script>
