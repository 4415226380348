<template>
    <div class="fixed-bottom-bar elevation-10">
        <div class="fixed-bottom-bar-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ThePageFooterBar',
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/misc.scss";

    /*
        @todo - this styling needs addressed... on CL detail page you can't click the "show more" button bc it was hidden behind the fixed element previously.
    */
    .fixed-bottom-bar {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        color: $page-footer-bar-fg;
        background-color: $page-footer-bar-bg;
        padding-left: 90px;
        z-index: 2;
    }

    .fixed-bottom-bar-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px;
    }

    /* Page Specific styling */
    /* Title detail queue  */
    .detail-queue-footer .fixed-bottom-bar-content {
        justify-content: space-between;
        align-items: center;
    }

    .detail-queue-footer .fixed-bottom-bar-content #save-and-done-button {
        display: flex;
        flex: 1 100%;
        justify-content: flex-end;
    }
    .po-detail-footer .fixed-bottom-bar-content {
        align-items: center;
        justify-content: space-between;
    }
</style>
