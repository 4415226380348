<template>
    <v-card
        :elevation="cardElevation"
        :loading="loading"
        :class="dynamicClasses"
    >
        <v-card-title v-if="!hideFilter">
            <slot name="filter">
                <v-text-field
                    v-model="filterValue"
                    appendIcon="mdi-magnify"
                    :label="filterLabel"
                    hideDetails
                    dense
                />
            </slot>
            <slot name="activator">
                <v-btn
                    v-if="!hideAdd"
                    class="activator-button"
                    outlined
                    @click="$emit('activator:click')"
                >
                    {{ addLabel }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </slot>
        </v-card-title>
        <v-data-table
            v-model="selectedRows"
            v-bind="$attrs"
            class="custom-datatable"
            :headers="headers"
            :items="items"
            :itemsPerPage="itemsPerPage"
            :search="filter"
            :showSelect="showSelect"
            :noResultsText="noResultsText"
            :noDataText="noDataText"
            :footerProps="{
                itemsPerPageOptions: itemsPerPageOptionsSorted,
            }"
            v-on="$listeners"
        >
            <template
                v-for="( i, name ) in $scopedSlots"
                v-slot:[name]="data"
            >
                <slot
                    :name="name"
                    v-bind="data"
                />
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

export default {
    name: 'TableBaseFilterable',
    inheritAttrs: false,
    props: {
        hideFilter: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        headers: {
            type: Array,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            required: false,
            default: 300,
            validator: ( value ) => ( Number.isInteger( value ) ), // No decimals
        },
        filterLabel: {
            type: String,
            required: false,
            default: 'Filter Results...',
        },
        addLabel: {
            type: String,
            required: false,
            default: 'Add',
        },
        noResultsText: {
            type: String,
            required: false,
            default: 'No Results Found for Filter',
        },
        noDataText: {
            type: String,
            required: false,
            default: 'No Results Found Matching Search Criteria',
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        cardElevation: {
            type: String,
            required: false,
            default: '0',
        },
        hideAdd: {
            type: Boolean,
            required: false,
            default: false,
        },
        filter: {
            type: String,
            required: false,
        },
        selected: {
            type: Array,
            required: false,
            default: () => [],
        },
        showSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            itemsPerPageOptions: [
                5,
                10,
                15,
                25,
                50,
                100,
                150,
                300,
                -1,
                this.itemsPerPage,
            ],
        };
    },
    computed: {
        itemsPerPageOptionsSorted() {
            return this.itemsPerPageOptions
                .filter( ( option, index ) => this.itemsPerPageOptions.indexOf( option ) === index )
                .sort( ( a, b ) => a - b );
        },
        filterValue: {
            get() {
                return this.filter;
            },
            set( val ) {
                this.$emit( 'update:filter', val );
            },
        },
        selectedRows: {
            get() {
                return this.selected;
            },
            set( val ) {
                this.$emit( 'update:selected', val );
            },
        },
        /** @returns { String } */
        dynamicClasses() {
            const classes = [];
            if ( this.outlined ) {
                classes.push( this.error ? 'error-border' : 'black-border' );
            }
            return classes.join( ' ' );
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/sass/customDatatable.scss";

    .activator-button {
        margin-left: 24px;
    }
</style>
