<template>
    <transition :name="transitionStyle">

        <!-- Modal Mask -->
        <div
            class="modal-mask"
            :style="maskStyleOverride"
        >

            <!-- Modal Wrapper -->
            <div class="modal-wrapper">

                <!-- Modal Container -->
                <div
                    class="modal-container"
                    :style="containerStyleOverride"
                >

                    <!-- SLOT - Header -->
                    <div
                        class="modal-header"
                    >
                        <div class="modal-header__content">
                            <slot name="header"/>
                        </div>
                        <!-- Close Modal Button -->
                        <div
                            v-if="shouldShowCloseButton"
                            class="cancel-button-container"
                        >
                            <v-btn
                                aria-label="close"
                                icon
                                @click="closeModal()"
                            >
                                <v-icon color="white">mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <!-- SLOT - Message -->
                    <slot name="message"></slot>

                    <!-- SLOT - Body -->
                    <div
                        class="modal-body"
                        :style="bodyStyleOverride"
                    >
                        <slot name="body"></slot>
                    </div>

                    <!-- SLOT - Footer -->
                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>

/**
 * @emits 'close'
 */
export default {
    name: 'ModalBase',
    props: {
        transitionStyle: {
            type: String,
            required: false,
            default: 'modal',
        },
        maskStyleOverride: {
            type: Object,
            required: false,
            // display: table aligns the modal to the center of the screen
            default: () => ( { display: 'table' } ),
        },
        containerStyleOverride: {
            type: Object,
            required: false,
        },
        bodyStyleOverride: {
            type: Object,
            required: false,
        },
        shouldShowCloseButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        headerBackgroundType: {
            type: String,
            required: false,
        },
    },
    methods: {
        closeModal() {
            this.$emit( 'close' );
        },
    },
};
</script>

<style lang="scss" scoped>
    /* Cancel Button Styles */
    @import "@/assets/sass/variables.scss";

    /* Modal Styles */
    /* Modal example found here: https://vuejs.org/v2/examples/modal.html */

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        transition: opacity .3s ease;
        color: black;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 300px;
        margin: 0 auto;
        padding: 1rem;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        overflow-y: auto;
    }

    .modal-header {
        position: relative;

        &__content{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
        }

        .cancel-button-container {
            position: absolute;
            top: 12px;
            right: 12px;
        }
        h3 {
            margin-top: 0;
            color: #42b983;
        }
        i:hover {
            cursor: pointer;
        }
    }

    .modal-body {
        margin: 1rem 0;
        color: #333333;
    }

    /* Styles used for transitionStyle prop */

    /* transitionStyle === 'modal' */
    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        transform: scale(1.1);
    }

    /* transitionStyle === 'slide-fade' */
    .slide-fade-enter-active {
        transition: all .2s ease;
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(-300px);
        opacity: 0;
    }

    /* Green header with bg image styling */
    .stylized .modal-wrapper .modal-container{
        padding: 0 0 0.5rem 0;
        text-align: center;
    }
    .stylized-error .modal-wrapper .modal-container {
        padding: 0 0 0.5rem 0;
    }
    .stylized .cancel-button-container,
    .stylized-error .cancel-button-container{
        padding-bottom: 0;
    }
    .stylized .cancel-button-container .material-icons,
    .stylized-error .cancel-button-container .material-icons{
        margin-top: 5px;
        margin-right: 10px;
        color: $white;
        transform: translateY(25%);
    }
    .stylized .modal-header {
        background-color: var(--v-primary-base);
        color: $white;
    }
    .stylized-error .modal-header {
        background-color: rgba(209, 52, 64, .9);
    }
    .stylized-warning .modal-header {
        background-color: rgba(249, 223, 25, .9);
    }
</style>
