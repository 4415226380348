<template>
    <div class="loader-icon-small-container">
        <div
            class="loading-icon circle"
            :style="overrideStyles"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'LoaderIconSmall',
    props: {
        overrideStyles: {
            type: Object,
            required: false,
            default: () => ( {} ),
        },
    },
};
</script>

<style scoped>
    .loader-icon-small-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        top: 5%;
    }

    .loading-icon {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 6px solid #ddd;
        border-top-color: #333;
        display: inline-block;
        margin: 0 8px;
        animation-name: spin;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    .circle {
        animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
