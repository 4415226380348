<template>
    <div class="editable-input">
        <div class="editable-input__row">
            <h3 class="column-title-heading">
                {{ label }}
            </h3>
        </div>

        <div class="editable-input__row">
            <!-- Currency -->
            <InputCurrency
                :ref="INPUT_REF_MAP[INPUT_TYPES.CURRENCY]"
                v-if="isTypeCurrency"
                v-model.number="localValue"
                :placeholder="placeholder"
                class="editable-input__input"
            />

            <!-- Number -->
            <v-text-field
                :ref="INPUT_REF_MAP[INPUT_TYPES.NUMBER]"
                v-if="isTypeNumber"
                v-model="localValue"
                :placeholder="placeholder"
                :rules="rules"
                outlined
                dense
                hideDetails
                class="editable-input__input"
            />

            <!-- Text -->
            <v-text-field
                :ref="INPUT_REF_MAP[INPUT_TYPES.TEXT]"
                v-if="isTypeText"
                v-model="localValue"
                :placeholder="placeholder"
                :rules="rules"
                outlined
                dense
                hideDetails
                class="editable-input__input"
            />
        </div>
    </div>
</template>
<script>
// Utils
// import ValidationUtil from '@/utils/ValidationUtil';

// Components
import InputCurrency from '@/components/base/inputs/InputCurrency';

const INPUT_TYPES = {
    CURRENCY: 'currency',
    TEXT: 'text',
    NUMBER: 'number',
};
const INPUT_REF_MAP = {
    [INPUT_TYPES.CURRENCY]: 'input-currency',
    [INPUT_TYPES.TEXT]: 'input-text',
    [INPUT_TYPES.NUMBER]: 'input-number',
};

export default {
    name: 'EditableInput',
    components: {
        InputCurrency,
    },
    props: {
        value: {
            type: [String, Number, null],
            default: null,
        },
        type: {
            type: String,
            default: INPUT_TYPES.TEXT,
            validator: (val) => Object.values(INPUT_TYPES).includes(val),
        },
        label: {
            type: String,
            default: '',
        },
        rules: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            INPUT_TYPES,
            INPUT_REF_MAP,

            hasError: false,
            emptyValuePlaceholderText: 'Not Set',

            // rules: {
            //     isNumber: [
            //         ValidationUtil.isOnlyDigits,
            //     ],
            // },
        };
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },

        /** @returns { Boolean } */
        isTypeCurrency() {
            return this.type === INPUT_TYPES.CURRENCY;
        },
        /** @returns { Boolean } */
        isTypeText() {
            return this.type === INPUT_TYPES.TEXT;
        },
        /** @returns { Boolean } */
        isTypeNumber() {
            return this.type === INPUT_TYPES.NUMBER;
        },

        /** @returns { Boolean } */
        isValueEmpty() {
            return this.value === null
                || this.value === '';
        },
        /** @returns { String } */
        placeholder() {
            return this.isValueEmpty
                ? this.emptyValuePlaceholderText
                : '';
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/app.scss";

.editable-input {
    display: flex;
    flex-flow: column nowrap;

    &__action {
        margin-bottom: auto;
        margin-left: .5rem;
    }

    &__row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &__input {
        width: 100%;
    }
}
</style>
