<template>
    <v-form
        class="sidebar-container"
        @submit.prevent="fetchUsingSelectedFilters()"
    >

        <!-- Search by EAN -->
        <div class="sidebar-container__section">
            <h3 class="sidebar-container__header">EAN</h3>
            <v-text-field
                v-model="isbn"
                class="search-input-field"
                type="text"
                dense
                hideDetails
                backgroundColor="white"
                placeholder="EAN"
                outlined
            />
        </div>

        <!-- Catalog Selection -->
        <div
            id="catalog-selection-container"
            class="sidebar-container__section"
        >
            <div class="sidebar-container__header">
                <h3>Catalog</h3>
                <v-btn
                    class="button-add-catalog"
                    small
                    color="white"
                    @click="manuallyAddCatalog()"
                >
                    Add
                </v-btn>
            </div>
            <BreadcrumbBase
                v-for="catalog in catalogs"
                :key="`${catalog.uid}-1`"
                :value="catalog.breadcrumb"
                :items="catalogItems"
                @selected="({ breadcrumb }) => updateCatalog(catalog, breadcrumb)"
                @input="(breadcrumb) => updateCatalog(catalog, breadcrumb)"
                @deleted="removeCatalog(catalog)"
            />
        </div>

        <!-- Shelf Date Range -->
        <div class="sidebar-container__section date-range-selector">
        <h3 class="sidebar-container__header">Shelf Date Range</h3>
            <div class="date-range-selector__row">
                <InputDateRange
                    :start-date.sync="shelfDateStart"
                    :end-date.sync="shelfDateEnd"
                    label="Shelf"
                    outlined
                />
            </div>
        </div>

        <!-- Section Start Date Range -->
        <div class="sidebar-container__section date-range-selector">
            <h3 class="sidebar-container__header">Section Start Date Range</h3>
            <div class="date-range-selector__row">
                <InputDateRange
                    :start-date.sync="sectionDateStart"
                    :end-date.sync="sectionDateEnd"
                    label="Section"
                    outlined
                />
            </div>
        </div>

        <!-- Catalog Listing Statuses -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__section__statuses">
                <h3 class="sidebar-container__header">Catalog Listing Statuses</h3>
                <CheckboxMultiSelectPersistable
                    v-model="catalogListingStatuses"
                    :leftColumnKeys="leftColumnKeys"
                />
            </div>
        </div>

        <!-- Title Designators Filter -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__section__designators">
                <h3 class="sidebar-container__header">Title Designators</h3>
                <SelectIncludeExclude
                    :initialOptions="titleDesignators"
                    :preSelectedOptions="filterTitleDesignators"
                    optionKey="id"
                    @updateOptions="( inclusiveList, exclusiveList ) => updateTitleDesignators( inclusiveList, exclusiveList )"
                />
            </div>
        </div>

        <!-- Catalog Listing Designators Filter -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__section__designators">
                <h3 class="sidebar-container__header">Catalog Listing Designators</h3>
                <SelectIncludeExclude
                    :initialOptions="catalogListingDesignators"
                    :preSelectedOptions="filterCatalogListingDesignators"
                    optionKey="id"
                    @updateOptions="( inclusiveList, exclusiveList ) => updateCatalogListingDesignators( inclusiveList, exclusiveList )"
                />
            </div>
        </div>

        <!-- Adoption Questions -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__section__adoption-questions">
                <h3 class="sidebar-container__header">Adoption Questions</h3>
                <MultiOptionAdoptionQuestions
                    :preSelectedIncludeExcludeOptions="adoptionQuestions"
                    optionKey="id"
                    @updatedLists="( inclusiveList, exclusiveList ) => adoptionQuestionsHandler( inclusiveList, exclusiveList )"/>
            </div>
        </div>

        <!-- Checkboxes -->
        <!-- Could be replaced with CheckboxMultiSelectPersistable -->
        <div class="sidebar-container__section sidebar-container__section__checkboxes">
            <!-- Title Research Done -->
            <div class="sidebar-container__section__checkboxes__item" @mousedown.prevent>
                <Checkbox
                    id="research-done"
                    v-model="titleResearchComplete"
                    class="sidebar-container__section__checkboxes__item__input"
                />
                <label for="research-done">
                    Title Research Done
                </label>
            </div>

            <!-- Title Research NOT Done -->
            <div class="sidebar-container__section__checkboxes__item" @mousedown.prevent>
                <Checkbox
                    id="research-not-done"
                    v-model="localTitleResearchNotComplete"
                    class="sidebar-container__section__checkboxes__item__input"
                />
                <label for="research-not-done">
                    Title Research NOT Done
                </label>
            </div>

            <!-- Only Active OTB Term -->
            <div class="sidebar-container__section__checkboxes__item" @mousedown.prevent>
                <Checkbox
                    id="only-active-otb"
                    v-model="termOTB"
                    class="sidebar-container__section__checkboxes__item__input"
                />
                <label for="only-active-otb">
                    Only Active OTB Term
                </label>
            </div>

            <!-- Only IA -->
            <div class="sidebar-container__section__checkboxes__item" @mousedown.prevent>
                <Checkbox
                    id="only-ia"
                    v-model="iaOnlyCb"
                    class="sidebar-container__section__checkboxes__item__input"
                />
                <label for="only-ia">
                    Only IA Listings
                </label>
            </div>

            <!-- Exclude IA -->
            <div class="sidebar-container__section__checkboxes__item" @mousedown.prevent>
                <Checkbox
                    id="exclude-ia"
                    v-model="iaExcludeCb"
                    class="sidebar-container__section__checkboxes__item__input"
                />
                <label for="exclude-ia">
                    Exclude IA Listings
                </label>
            </div>

        </div>
        <!-- Limit Results Input -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__section__limits">
                <h3 class="sidebar-container__header">Limit</h3>
                <v-text-field
                    id="input-result-limit"
                    v-model.number="limit"
                    outlined
                    dense
                    hideDetails
                    backgroundColor="white"
                    :rules="[rules.validNumber]"
                />
            </div>
        </div>
        <!-- Apply Filters -->
        <div class="sidebar-container__section sidebar-container__section__submit">
            <v-btn
                color="white"
                type="submit"
            >
                Apply
            </v-btn>
        </div>
    </v-form>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Constants
import STATUS_LIST, { STATUS_LIST_COLUMNS } from '@/constants/catalogListing/statusList';

// Components
import Checkbox from '@/components/base/Checkbox';
import CheckboxMultiSelectPersistable from '@/components/base/multiselect/CheckboxMultiSelectPersistable';
import SelectIncludeExclude from '@/components/base/multioption/SelectIncludeExclude';
import BreadcrumbBase from '@/components/base/breadcrumbs/BreadcrumbBase';
import InputDateRange from '@/components/InputDateRange';

import MultiOptionAdoptionQuestions from './sidebarFilterCatalogListings/MultiOptionAdoptionQuestions';

export default {
    name: 'SidebarFilterCatalogListings',
    components: {
        Checkbox,
        CheckboxMultiSelectPersistable,
        SelectIncludeExclude,
        MultiOptionAdoptionQuestions,
        BreadcrumbBase,
        InputDateRange,
    },
    props: {
        productCode: {
            type: String,
            required: false,
        },
        titleResearchNotComplete: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            breadcrumb: {},
            rules: {
                validNumber: (value) => {
                    if (!value) return true;
                    return !Number.isNaN(Number(value));
                },
            },
        };
    },
    computed: {
        ...mapGetters( {
            catalogListingDesignators: 'GlobalResources/catalogListingDesignators',
            titleDesignatorCount: 'GlobalResources/titleDesignatorListCount',
            hasNoTitleDesignators: 'GlobalResources/hasNoTitleDesignators',
            hasCatalogs: 'CatalogListing/hasCatalogs',
            allCatalogsHaveLocation: 'CatalogListing/allCatalogsHaveLocation',
        } ),
        ...mapState( {
            titleDesignators: ( state ) => state.GlobalResources.titleDesignatorList,
            filters: ( state ) => state.CatalogListing.filters,
            catalogFilterLists: ( state ) => state.CatalogListing.catalogFilterLists,
            catalogListingStatusList: ( state ) => state.CatalogListing.catalogListingStatusList,
            locationList: ( state ) => state.GlobalResources.locationList,
        } ),
        leftColumnKeys() {
            return this.catalogListingStatusList.filter(
                ( status ) => STATUS_LIST_COLUMNS[ status.key ] === 0,
            ).map( ( status ) => status.key );
        },

        catalogItems() {
            return [
                {
                    placeholder: 'Location',
                    displayedKey: ( location ) => FormatUtil.locationNameToPrettyName( location.name ),
                    actionName: '',
                    list: this.locationList,
                    childOf: [],
                    id: 'location',
                },
                {
                    placeholder: 'Division',
                    displayedKey: 'name',
                    actionName: 'CatalogListing/getDivisionList',
                    list: [],
                    childOf: [ 'location' ],
                    id: 'division',
                },
                {
                    placeholder: 'Term',
                    displayedKey: 'name',
                    actionName: 'CatalogListing/getTermList',
                    list: [],
                    childOf: [ 'division' ],
                    id: 'term',
                },
                {
                    placeholder: 'Department',
                    displayedKey: 'code',
                    actionName: 'CatalogListing/getDepartmentList',
                    list: [],
                    childOf: [ 'term' ],
                    id: 'department',
                },
                {
                    placeholder: 'Course',
                    displayedKey: 'code',
                    actionName: 'CatalogListing/getCourseList',
                    list: [],
                    childOf: [ 'department' ],
                    id: 'course',
                },
                {
                    placeholder: 'Section',
                    displayedKey: 'code',
                    actionName: 'CatalogListing/getSectionList',
                    list: [],
                    childOf: [ 'course' ],
                    id: 'section',
                },
            ];
        },

        /** @returns { Boolean } */
        localTitleResearchNotComplete: {
            get() {
                return this.titleResearchNotComplete;
            },
            set( value ) {
                this.emitResearchCompletionStatus( value );
                if ( value ) this.titleResearchComplete = false;
            },
        },

        catalogListingStatuses: {
            get() {
                return this.filters.catalogListingStatuses;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterCatalogListingStatuses', { catalogListingStatuses: val } );
            },
        },
        catalogs: {
            get() {
                return this.filters.catalogs;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterCatalogs', { catalogs: val } );
            },
        },
        isbn: {
            get() {
                return this.filters.isbn;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterIsbn', { isbn: val } );
            },
        },
        sectionDateStart: {
            get() {
                return this.filters.sectionDateStart;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterSectionDateStart', { sectionDateStart: val } );
            },
        },
        sectionDateEnd: {
            get() {
                return this.filters.sectionDateEnd;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterSectionDateEnd', { sectionDateEnd: val } );
            },
        },
        shelfDateStart: {
            get() {
                return this.filters.shelfDateStart;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterShelfDateStart', { shelfDateStart: val } );
            },
        },
        shelfDateEnd: {
            get() {
                return this.filters.shelfDateEnd;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterShelfDateEnd', { shelfDateEnd: val } );
            },
        },
        primarySupplier: {
            get() {
                return this.filters.primarySupplier;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterPrimarySupplier', { primarySupplier: val } );
            },
        },
        limit: {
            get() {
                return this.filters.limit;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterLimit', { limit: val } );
            },
        },
        termOTB: {
            get() {
                return this.filters.termOTB;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterTermOTB', { termOTB: val } );
            },
        },
        iaOnly: {
            get() {
                return this.filters.iaOnly;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterIaOnly', { iaOnly: val } );
            },
        },
        iaOnlyCb: {
            get() {
                return this.filters.iaOnlyCb;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterIaOnlyCb', { iaOnlyCb: val } );
            },
        },
        iaExcludeCb: {
            get() {
                return this.filters.iaExcludeCb;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterIaExcludeCb', { iaExcludeCb: val } );
            },
        },
        titleResearchComplete: {
            get() {
                return this.filters.titleResearchComplete;
            },
            set( val ) {
                if ( val ) {
                    this.emitResearchCompletionStatus( false );
                }
                this.$store.dispatch( 'CatalogListing/setFilterTitleResearchComplete', { titleResearchComplete: val } );
            },
        },
        filterTitleDesignators: {
            get() {
                return this.filters.titleDesignators;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterTitleDesignators', { titleDesignators: val } );
            },
        },
        filterCatalogListingDesignators: {
            get() {
                return this.filters.catalogListingDesignators;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterCatalogListingDesignators', { catalogListingDesignators: val } );
            },
        },
        adoptionQuestions: {
            get() {
                return this.filters.adoptionQuestions;
            },
            set( val ) {
                this.$store.dispatch( 'CatalogListing/setFilterAdoptionQuestions', { adoptionQuestions: val } );
            },
        },
    },
    watch: {
        productCode: {
            handler: 'productCodeUpdated',
            immediate: true,
        },
    },
    beforeMount() {
        this.$store.dispatch( 'GlobalResources/init' );
    },
    methods: {
        ...mapMutations( {
            clearCurrentListing: 'CLEAR_CURRENT_LISTING',
            clearCurrentTitle: 'CLEAR_CURRENT_TITLE',
        } ),

        emitResearchCompletionStatus( value ) {
            this.$emit( 'updateTitleResearchNotComplete', value );
        },

        productCodeUpdated() {
            if ( this.productCode ) {
                this.$store.dispatch( 'CatalogListing/setFilterIsbn', { isbn: this.productCode } );
                this.fetchUsingSelectedFilters();
            }
        },
        updateCatalog( catalog, breadcrumb ) {
            if ( !Object.keys( breadcrumb ).includes( 'location' ) ) {
                // Delete the catalog if the location was removed
                this.removeCatalog( catalog );
            } else {
                this.$store.dispatch( 'CatalogListing/updateCatalogByUid', { uid: catalog.uid, breadcrumb } );
            }
        },
        manuallyAddCatalog() {
            if ( this.allCatalogsHaveLocation ) {
                this.$store.dispatch( 'CatalogListing/addEmptyCatalog' );
            }
        },
        removeCatalog( catalog ) {
            this.$store.dispatch( 'CatalogListing/removeCatalogFromFilterCatalogs', { catalog } );
            this.manuallyAddCatalog();
        },

        updateTitleDesignators( includedDesignators, excludedDesignators ) {
            this.filterTitleDesignators = { include: includedDesignators, exclude: excludedDesignators };
        },
        updateCatalogListingDesignators( includedDesignators, excludedDesignators ) {
            this.filterCatalogListingDesignators = { include: includedDesignators, exclude: excludedDesignators };
        },
        adoptionQuestionsHandler( inclusiveList, exclusiveList ) {
            this.adoptionQuestions = { include: inclusiveList, exclude: exclusiveList };
        },

        getAdoptionQuestionValue( id ) {
            if ( this.adoptionQuestions.include.map( ( item ) => item.id ).includes( id ) ) return true;
            if ( this.adoptionQuestions.exclude.map( ( item ) => item.id ).includes( id ) ) return false;
            return null;
        },
        fetchUsingSelectedFilters() {
            this.clearCurrentTitle();
            this.clearCurrentListing();
            // If we've selected at least one location to filter by,
            // return a catalog with only the attributes we want,
            // otherwise send an empty array
            let formattedCatalogs;
            if ( this.hasCatalogs ) {
                formattedCatalogs = this.catalogs
                    .filter( ( catalog ) => catalog.breadcrumb.location?.id )
                    .map( ( catalog ) => ( {
                        locationId: catalog.breadcrumb.location.id,
                        divisionId: catalog.breadcrumb.division?.id ?? 0,
                        termId: catalog.breadcrumb.term?.id ?? 0,
                        departmentId: catalog.breadcrumb.department?.id ?? 0,
                        courseId: catalog.breadcrumb.course?.id ?? 0,
                        sectionId: catalog.breadcrumb.section?.id ?? 0,
                    } ) );
            }
            const catalogListingStatusesToSend = [];
            Object.keys( this.catalogListingStatuses ).forEach( ( key ) => {
                if ( this.catalogListingStatuses[ key ] ) {
                    catalogListingStatusesToSend.push( STATUS_LIST[ key ] );
                }
            } );

            this.$store.dispatch( 'CatalogListing/getByFilters', {
                catalogListingStatuses: catalogListingStatusesToSend,
                catalogs: formattedCatalogs,
                isbn: FormatUtil.sanitizeIsbn( this.isbn ),
                limit: this.limit,
                primarySupplier: this.primarySupplier,
                sectionDateStart: this.sectionDateStart,
                sectionDateEnd: this.sectionDateEnd,
                shelfDateStart: this.shelfDateStart,
                shelfDateEnd: this.shelfDateEnd,
                termOTB: this.termOTB,
                iaOnly: this.iaOnly,
                catalogListingDesignators: this.filterCatalogListingDesignators.include,
                excludeCatalogListingDesignators: this.filterCatalogListingDesignators.exclude,
                titleDesignators: this.filterTitleDesignators.include,
                titleExcludeDesignators: this.filterTitleDesignators.exclude,
                bindInComponentRequired: this.getAdoptionQuestionValue( 'bindInComponentRequired' ),
                flexibleOnEdition: this.getAdoptionQuestionValue( 'flexibleOnEdition' ),
                titleResearchNotComplete: ( () => {
                    if ( this.titleResearchNotComplete === false && this.titleResearchComplete === false ) return null;
                    return this.titleResearchNotComplete;
                } )(),
            } );

            // Scroll to the top of the page
            window.scrollTo( 0, 0 );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/sidebar.scss";

::v-deep.v-input {
    margin-top: 0 inherit;
}

.sidebar-container {
    ::v-deep .v-input .v-input__control {
        border-radius: 4px;
    }
    ::v-deep.v-btn__content {
        color: var(--v-primary-base);
    }
    :last-child {
        border-bottom: none;
    }
    ::v-deep .reset-link {
        color: white;
    }
    ::v-deep .multi-option-base__icon {
        display: none;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__section {
        .button-add-catalog {
            font-size: .8rem;
        }

        &__statuses {
            padding-top: 0;
        }

        &__checkboxes__item {
            display: flex;
            align-items: center;

            &__input {
                padding-top: 0;
                margin-top: 0;
                margin-bottom: 0;
            }

            label {
                /* this is to make sure the cursor is a pointer cross-browser */
                cursor: pointer;
                text-transform: capitalize;
                text-align: left;
            }
        }

        &__submit {
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: flex;
            justify-content: space-around;
        }
    }
}

.date-range-selector {
    &__row {
        display: flex;
        align-items: center;
        .v-input {
            width: 125px;
        }
        p {
            margin: 0 0.5rem 0;
        }
    }
    h3 {
        margin-bottom: 0.5rem;
    }
}
</style>
