<template>
    <div class="listing-detail-info-container box-shadow">
        <div class="listing-detail-info-content contextual-info-items-container">

            <!-- Catalog -->
            <div>
                <h2>Catalog</h2>
                <CatalogTable
                    :listings="formattedCurrentCatalogListing"
                    prefix="current"
                    isEditable
                    showMessagingStatus
                    hideFilter
                />
            </div>

            <!-- Open To Buy-->
            <ToggleableContent contentTitle="Open to Buy">
                <TableOpenToBuy
                    prefix="otb"
                    :loading="openToBuyInfo.inFlight"
                    :items="openToBuyInfo.list"
                />
            </ToggleableContent>

            <!-- Additional Listings -->
            <ToggleableContent contentTitle="Additional Listings">
                <div>
                    <LoaderIcon v-if="additionalListings.inFlight"/>
                    <CatalogTable
                        v-else
                        prefix="all"
                        :listings="additionalListings.list"
                        showOverrides
                    />
                </div>
            </ToggleableContent>

        </div>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import LoaderIcon from '@/components/base/loaders/LoaderIconSmall';
import TableOpenToBuy from '@/components/tables/TableOpenToBuy';
import CatalogTable from './contextualInfoGroup/CatalogTable';
import ToggleableContent from './contextualInfoGroup/ToggleableContent';

export default {
    name: 'ContextualInfoGroup',
    components: {
        LoaderIcon,
        TableOpenToBuy,
        CatalogTable,
        ToggleableContent,
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),
        ...mapState( {
            currentCatalogListing: ( state ) => state.currentCatalogListing,
            currentTitle: ( state ) => state.currentTitle,
            openToBuyInfo: ( state ) => state.CatalogListing.Detail.openToBuy,
            additionalListings: ( state ) => state.CatalogListing.Detail.additionalCatalogListings,
        } ),

        /** @returns { Array } */
        formattedCurrentCatalogListing() {
            // Nest the currentCatalogListing to mirror the currentListings and additionalListings structure
            return [ { catalogListing: this.currentCatalogListing, designators: this.currentTitle.designators } ];
        },
    },
};
</script>

<style lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/contextualinfo.scss";

    .listing-detail-info-container {
        overflow-x: auto;

        th, td {
            font-size: 12px !important;
        }
    }

    .item-container {
        display: flex;
        justify-content: space-between;
        padding: 5px;
    }

    .item-background {
        color: $coninfo-item-bg;
    }

    .contextual-info-items-container {
        >:not(:last-child) {
            padding-bottom: 2rem;
            border-bottom: 1px solid #cdcdcd;
        }
    }

</style>
