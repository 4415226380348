<template>
    <v-autocomplete
        id="autocomplete"
        v-model="selected"
        backgroundColor="white"
        dense
        hideDetails
        :items="items"
        :outlined="outlined"
        :itemText="itemText"
        :aria-required="required"
        :placeholder="placeholder"
        returnObject
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
export default {
    name: 'AutocompleteBase',
    props: {
        value: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        outlined: {
            type: Boolean,
            required: false,
            defaultValue: false,
        },
        placeholder: {
            type: String,
            required: false,
            defaultValue: '',
        },
        required: {
            type: String,
            required: false,
            defaultValue: false,
        },
        itemText: {
            type: [ String, Array, Function ],
            required: false,
            defaultValue: null,
        },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
    #autocomplete {
        width: 250px;
    }
</style>
