<template>
    <MultiOptionBase
        v-bind="$attrs"
        :fullItemList="optionList"
        displayedKey="name"
        mode="includeExclude"
        v-on="$listeners"
        @includeExclude="( inclusiveList, exclusiveList ) => $emit( 'updatedLists', inclusiveList, exclusiveList )"
    />
</template>

<script>
// Components
import MultiOptionBase from '@/components/base/multioption/MultiOptionBase';

export default {
    name: 'MultiOptionAdoptionQuestions',
    components: {
        MultiOptionBase,
    },
    data() {
        return {
            optionList: [
                {
                    id: 'bindInComponentRequired',
                    name: 'Bind-in',
                },
                {
                    id: 'flexibleOnEdition',
                    name: 'Move Edition Allowed',
                },
            ],
        };
    },
};
</script>

<style scoped>
</style>
