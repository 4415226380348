<template>
    <div
        class="custom-container"
        :class="[ fullSidebar ? 'sidebar-full' : 'sidebar-thin' ]"
    >
        <TheSidebar :fullSize="fullSidebar">
            <SidebarFilterCatalogListings
                v-show="fullSidebar"
                :productCode="productCode"
                :titleResearchNotComplete="titleResearchNotComplete"
                @updateTitleResearchNotComplete="( value ) => setTitleResearchNotComplete( value )"
            />
        </TheSidebar>

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            :fullSize="fullSidebar"
            :backArrow="showDetailView"
            @goBack="$router.push( { name: 'TitleResearchCatalogListings' } )"
        />

        <section class="main">
            <v-dialog
                v-model="showConfirmationModal"
                persistent
                width="400px"
            >
                <v-card>
                    <v-toolbar
                        color="primary"
                        dark
                    >
                        <h2>Leave without saving?</h2>
                        <v-spacer />
                        <v-btn
                            color="white"
                            icon
                            @click="cancelRouteLeave()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text class="mt-8">
                        <p>You have unsaved changes. Are you sure you want to leave without saving?</p>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            outlined
                            @click="cancelRouteLeave()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="confirmRouteLeave()"
                        >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Catalog Listing Detail View -->
            <DetailView
                v-show="showDetailView"
                @showSummary="showSummaryWithMessage"
            />

            <!-- Catalog Listing Summary View -->
            <SummaryView
                v-show="!showDetailView"
                :titleResearchNotComplete="titleResearchNotComplete"
                @clickedListing="( listing ) => $router.push( {
                    name: 'TitleResearchCatalogListings',
                    params: { catalogListingId: listing.catalogListing.id }
                } )"
            />
        </section>
    </div>
</template>

<script>
// Vuex
import {
    mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';

import SidebarFilterCatalogListings from './catalogListings/SidebarFilterCatalogListings';
import DetailView from './catalogListings/DetailView';
import SummaryView from './catalogListings/SummaryView';

export default {
    name: 'CatalogListings',
    components: {
        SummaryView,
        DetailView,
        ThePageHeadingBar,
        TheSidebar,
        SidebarFilterCatalogListings,
    },
    // Handles navigation from detail page to search page
    beforeRouteUpdate( to, from, next ) {
        this.handleReroute( to, from, next );
    },
    props: {
        // this is handled by the sidebar filter component. and is filled from route.query.productCode
        productCode: {
            type: String,
            required: false,
        },
        catalogListingId: {
            type: Number,
            required: false,
        },
        adoptionMaterialId: {
            type: [ String, Number ],
            required: false,
        },
    },
    data() {
        return {
            fullSidebar: true,
            titleResearchNotComplete: false,
            showDetailView: false,

            message: '',
            to: null,
            showConfirmationModal: false,
            unsavedStatusChangesDialog: false,
        };
    },
    computed: {
        ...mapGetters( {
            hasTitleModifications: 'CatalogListing/Detail/hasTitleModifications',
            hasCatalogListingModifications: 'hasCurrentCatalogListingModifications',
        } ),
        ...mapState( {
            catalogListings: ( state ) => state.CatalogListing.list,
            currentCatalogListing: ( state ) => state.currentCatalogListing,
            statusModified: ( state ) => state.TitleGroup.statusModified,
        } ),
        /** @returns { Boolean } */
        hasModifications() {
            return this.hasTitleModifications
                || this.hasCatalogListingModifications
                || this.statusModified;
        },
        /** @returns { Boolean } */
        canLeaveRoute() {
            return !this.showDetailView
                || !this.hasModifications
                || !!this.to;
        },
        /** @returns { String } */
        pageHeading() {
            return this.showDetailView
                ? 'Catalog Listing Detail'
                : 'Catalog Listings';
        },
    },
    watch: {
        catalogListingId: {
            handler: 'initialize',
            immediate: true,
        },
    },
    beforeDestroy() {
        this.clearCurrentListing();
        this.clearCurrentTitle();
    },
    methods: {
        ...mapActions( {
            getCatalogListingById: 'CatalogListing/getById',
            getByAdoptionMaterialId: 'CatalogListing/getByAdoptionMaterialId',
        } ),
        ...mapMutations( {
            clearCurrentListing: 'CLEAR_CURRENT_LISTING',
            clearCurrentTitle: 'CLEAR_CURRENT_TITLE',
            setSuccessNotification: 'SET_SUCCESS_NOTIFICATION',
        } ),

        async initialize() {
            if ( this.catalogListingId ) {
                try {
                    await this.getCatalogListingById( {
                        catalogListingId: this.catalogListingId,
                    } );
                    this.loadListingDetailView();
                } catch ( error ) {
                    this.loadListingSummaryView();
                }
            } else if ( this.adoptionMaterialId ) {
                try {
                    await this.getByAdoptionMaterialId( {
                        adoptionMaterialId: this.adoptionMaterialId,
                    } );
                    this.loadListingSummaryView();
                } catch ( error ) {
                    this.loadListingSummaryView();
                }
            } else {
                this.loadListingSummaryView();
            }
        },
        setTitleResearchNotComplete( value ) {
            this.titleResearchNotComplete = value;
        },

        loadListingDetailView() {
            this.fullSidebar = false;
            this.showDetailView = true;
        },
        loadListingSummaryView() {
            this.fullSidebar = true;
            this.showDetailView = false;
        },

        showSummaryWithMessage( message ) {
            // Message will be shown by handleReroute
            this.message = message;
            this.$router.push( { name: 'TitleResearchCatalogListings' } );
        },
        confirmRouteLeave() {
            this.$router.push( this.to );
            this.to = null;
            this.showConfirmationModal = false;
        },
        cancelRouteLeave() {
            this.to = null;
            this.showConfirmationModal = false;
        },
        handleReroute( to, from, next ) {
            if ( this.canLeaveRoute ) {
                if ( this.message ) {
                    this.setSuccessNotification( this.message );
                }
                next();
                this.message = '';
                this.to = null;
            } else {
                this.to = to;
                this.showConfirmationModal = true;
            }
        },
    },
};
</script>

<style scoped>
    main{
        grid-area: main;
    }
</style>
