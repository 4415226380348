<script setup>
import { ref, computed } from 'vue';
import moment from 'moment';

const emits = defineEmits(['update:startDate', 'update:endDate']);
const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    outlined: {
        type: Boolean,
        default: false,
    },
});

const dateFormat = 'MM/DD/YYYY';
const startDateMenu = ref(false);
const startDateMenuRef = ref(`${props.label.toLowerCase()}startDate`);
const endDateMenu = ref(false);
const endDateMenuRef = ref(`${props.label.toLowerCase()}endDate`);
const endDatePickerDate = ref('');

const rules = {
    validDate: (value) => {
        if (!value) {
            return true;
        }

        return moment(value, dateFormat, true).isValid();
    },
};

const formatDate = (date) => {
    if (!date) return null;

    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
};
const parseDate = (date) => {
    if (!date) return null;

    return moment(date).format('YYYY-MM-DD');
};

const localStartDate = computed({
    get: () => props.startDate,
    set: (newValue) => {
        endDatePickerDate.value = newValue;
        emits('update:startDate', newValue);
    },
});
const localEndDate = computed({
    get: () => props.endDate,
    set: (newValue) => {
        emits('update:endDate', newValue);
    },
});
const formattedStartDate = computed({
    get: () => formatDate(props.startDate),
    set: (newValue) => {
        localStartDate.value = parseDate(newValue);
    },
});
const formattedEndDate = computed({
    get: () => formatDate(props.endDate),
    set: (newValue) => {
        localEndDate.value = parseDate(newValue);
    },
});

function updateFormattedStartDate(dateString) {
    startDateMenu.value = false;
    if (dateString.length < 10) return;
    formattedStartDate.value = dateString;
}
function updateFormattedEndDate(dateString) {
    endDateMenu.value = false;
    if (dateString.length < 10) return;
    formattedEndDate.value = dateString;
}
</script>

<template>
    <div class="d-flex align-center">
        <v-menu
            :ref="startDateMenuRef"
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template #activator="{ on, attrs }">
                <v-text-field
                    :value="formattedStartDate"
                    :aria-label="`${label} Start Date`"
                    dense
                    :outlined="outlined"
                    hide-details
                    clearable
                    :placeholder="dateFormat"
                    persistent-placeholder
                    background-color="white"
                    validate-on-blur
                    :rules="[rules.validDate]"
                    v-bind="attrs"
                    @keydown.tab="startDateMenu = false"
                    @input="(value) => updateFormattedStartDate(value)"
                    @click:clear="localStartDate = null"
                    v-on="on"
                >
                    <template #append>
                        <v-icon @click="startDateMenu = !startDateMenu">
                            mdi-calendar
                        </v-icon>
                    </template>
                </v-text-field>
            </template>
            <v-date-picker v-model="localStartDate" no-title :max="endDate" @input="startDateMenu = false" />
        </v-menu>

        <span class="px-2">-</span>

        <v-menu
            :ref="endDateMenuRef"
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template #activator="{ on, attrs }">
                <v-text-field
                    :value="formattedEndDate"
                    :aria-label="`${label} End Date`"
                    dense
                    :outlined="outlined"
                    hide-details
                    clearable
                    :placeholder="dateFormat"
                    persistent-placeholder
                    background-color="white"
                    validate-on-blur
                    :rules="[rules.validDate]"
                    v-bind="attrs"
                    @keydown.tab="endDateMenu = false"
                    @input="(value) => updateFormattedEndDate(value)"
                    @click:clear="localEndDate = null"
                    v-on="on"
                >
                    <template #append>
                        <v-icon @click="endDateMenu = !endDateMenu">mdi-calendar</v-icon>
                    </template>
                </v-text-field>
            </template>
            <v-date-picker v-model="localEndDate" no-title :min="startDate" :picker-date.sync="endDatePickerDate" @input="endDateMenu = false" />
        </v-menu>
    </div>
</template>