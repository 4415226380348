<template>
    <v-dialog
        :value="value"
        width="500px"
        @close="handleModalCancel()"
    >
        <v-card>
            <v-card-title>How would you like to proceed?</v-card-title>
            <v-card-text>You updated your Grouping Status but did not save that change. What would you like to save?</v-card-text>
            <v-card-actions class="button-group">
                <v-spacer />
                <v-btn
                    outlined
                    color="primary"
                    @click="handleModalCancel()">
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="handleModalConfirm( true )">
                    Save Only Title Changes
                </v-btn>
                <v-btn
                    color="primary"
                    @click="handleModalConfirm( false )">
                    Save All Changes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalUnsavedTitleGroupingChanges',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        handleModalCancel() {
            this.$emit( 'input', false );
        },
        handleModalConfirm( titleOnly ) {
            this.$emit( 'input', false );
            this.$emit( 'confirm', titleOnly );
        },
    },
};
</script>

<style lang="scss" scoped>
    .button-group {
        gap: 0.5rem;
        padding-bottom: 1rem !important;
    }
</style>
