<template>
    <AutocompleteBase
        v-model="selectedAssignee"
        :items="authorizedAssigneeList"
        itemText="name"
        placeholder="Select an assignee"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import AutocompleteBase from '@/components/base/dropdowns/AutocompleteBase';

/**
 * @emits 'assignee' => { String } username
 */
export default {
    name: 'DropdownSelectAssignee',
    components: {
        AutocompleteBase,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            assignee: {},
        };
    },
    computed: {
        ...mapGetters( {
            hasAuthorizedAssigneeList: 'Title/Assignment/hasAuthorizedAssigneeList',
        } ),
        ...mapState( {
            authorizedAssigneeList: ( state ) => state.Title.Assignment.authorizedAssigneeList,
        } ),
        selectedAssignee: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
    beforeMount() {
        if ( !this.hasAuthorizedAssigneeList ) {
            this.$store.dispatch( 'Title/Assignment/getAuthorizedAssigneeList' );
        }
    },
};
</script>
