<template>
    <i class="material-icons is-usable">
        {{ iconName }}
    </i>
</template>

<script>
// Icons can be found: https://material.io/tools/icons/?style=baseline

export default {
    name: 'MaterialIcon',
    props: {
        iconName: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.is-usable {
    cursor: pointer;
    font-size: 16px;
    font-variant-caps: normal; /* font breaks without this */
}
</style>
