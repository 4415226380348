<template>
    <TableBaseFilterable
        class="table-open-to-buy"
        :items="items"
        :loading="loading"
        :headers="table.headers"
        :filter.sync="table.filter"
        filterLabel="Filter Open to Buy..."
        :noDataText="( loading ? 'Searching for OTB...' : 'No Open to Buy Results' )"
        hideAdd
    >
        <template #[`item.overrides`]="{item}">
            <div v-if="item.newOnly || item.printOnlyDesignator">
                <div v-if="item.newOnly">New Only </div>
                <div v-if="item.printOnlyDesignator">Print Only</div>
            </div>
            <div v-else>None</div>
        </template>
    </TableBaseFilterable>
</template>

<script>
// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'TableOpenToBuy',
    components: {
        TableBaseFilterable,
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => ( [] ),
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        prefix: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            table: {
                filter: '',
                headers: [
                    {
                        text: 'Division Name',
                        value: 'divisionName',
                    },
                    {
                        text: 'Term Name',
                        value: 'termName',
                    },
                    {
                        text: 'Shelf Date',
                        value: 'shelfDate',
                    },
                    {
                        text: 'Sourcing End Date',
                        value: 'sourcingEndDate',
                    },
                    {
                        text: 'Overrides',
                        value: 'overrides',
                    },
                    {
                        text: 'Base QTP',
                        value: 'baseQtp',
                        align: 'end',
                    },
                    {
                        text: 'Sales',
                        value: 'salesCount',
                        align: 'end',
                    },
                    {
                        text: 'Completed Orders',
                        value: 'completedOrdersCount',
                        align: 'end',
                    },
                    {
                        text: 'Remaining QTP',
                        value: 'remainingQtp',
                        align: 'end',
                    },
                    {
                        text: 'All On Hand',
                        value: 'allOnHand',
                        align: 'end',
                    },
                    {
                        text: 'All In Transit',
                        value: 'allInTransit',
                        align: 'end',
                    },
                    {
                        text: 'All On Order',
                        value: 'allOnOrder',
                        align: 'end',
                    },
                    {
                        text: 'All Incoming Rentals',
                        value: 'allIncomingRentals',
                        align: 'end',
                    },
                    {
                        text: 'Final OTB',
                        value: 'finalOtb',
                        align: 'end',
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.table-open-to-buy {
    overflow-x: auto;
}
</style>
