<template>
    <v-currency-field
        v-model="currencyValue"
        class="v-currency-field"
        :disabled="disabled"
        :readonly="readOnly"
        :required="required"
        placeholder="Not Set"
        dense
        hideDetails
        outlined
        :allow-negative="false"
        @blur="$emit( 'blur', $event.target )"
    />
</template>

<script>
// Based off v-currency field: https://phiny1.github.io/v-currency-field/started.html#usage\\
// Which is a Vuetify implementation of Vue Currency input: https://dm4t2.github.io/vue-currency-input/
// There are many configurations we can add to this component, such as internationalization

export default {
    name: 'InputCurrency',
    props: {
        value: {
            type: [ Number, String ],
            required: false,
            validator: ( value ) => (
                typeof value === 'string'
                || typeof value === 'number'
                || value === null
            ),
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        currencyValue: {
            get() {
                if (this.value === 0) return 0;
                return this.value || ''; // Allow placeholder to show
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
};
</script>
