<template>
    <div>
        <!-- Top Page Information -->
        <div class="page-info-top"></div>

        <ModalUnsavedTitleGroupingChanges
            v-model="showModal"
            @confirm="( titleOnly ) => titleOnly ? resetTitleGroupingStatus() : persistAllChanges()"
        />

        <!-- Listing Detail -->
        <ListingDetail
            :key="detailReRenderKey"
            v-if="hasCurrentCatalogListing"
        />

        <!-- Page Footer Bar -->
        <ThePageFooterBar :key="( detailReRenderKey + 1 )">
            <!-- Previous Button -->
            <div class="flex-footer-wrapper">
                <v-btn
                    :disabled="modificationsInFlight || catalogListingSearchLoading || !canGoToPreviousListing"
                    :loading="modificationsInFlight || catalogListingSearchLoading"
                    outlined
                    color="primary"
                    @click="showPreviousListing()"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                    Previous
                </v-btn>
            </div>

            <div id="footer-modifiers">
                <!-- Misc Info -->
                <div class="read-only-item">
                    <label>Print Only:</label>
                    <span class="read-only-item-text">
                        {{ currentCatalogListing.printOnlyRequired ? 'YES' : 'NO' }}
                    </span>
                </div>

                <div class="read-only-item">
                    <label>Bind-In Component Required:</label>
                    <span class="read-only-item-text">
                        {{ currentCatalogListing.bindInComponentRequired ? 'YES' : 'NO' }}
                    </span>
                </div>

                <!-- Required/optional info -->
                <div class="read-only-item">
                    <label>Req/Opt:</label>
                    <span class="read-only-item-text">
                        {{ currentCatalogListing.required ? currentCatalogListing.required : 'NONE' }}
                    </span>
                </div>

                <!-- Listing Status Selection -->
                <CurrentListingStatusSelection v-if="hasCurrentCatalogListing" />
            </div>

            <!-- Save Button -->
            <v-btn
                :loading="modificationsInFlight || catalogListingSearchLoading"
                :disabled="modificationsInFlight || catalogListingSearchLoading"
                class="button-save"
                color="primary"
                @click="validateGroupingStatusSaved( saveTitleAndListingModifications )"
            >
                Save
            </v-btn>

            <!-- Next Button -->
            <v-btn
                :loading="modificationsInFlight || catalogListingSearchLoading"
                :disabled="modificationsInFlight || catalogListingSearchLoading"
                outlined
                color="primary"
                @click="validateGroupingStatusSaved( showNextListing )"
            >
                Next
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </ThePageFooterBar>
    </div>
</template>

<script>
// Vuex
import {
    mapActions, mapGetters, mapState,
} from 'vuex';

// Components
import ThePageFooterBar from '@/components/ThePageFooterBar';
import ModalUnsavedTitleGroupingChanges from '@/views/common/ModalUnsavedTitleGroupingChanges';
import CurrentListingStatusSelection from './detailView/CurrentListingStatusSelection';
import ListingDetail from './detailView/ListingDetail';

export default {
    name: 'DetailView',
    components: {
        CurrentListingStatusSelection,
        ModalUnsavedTitleGroupingChanges,
        ListingDetail,
        ThePageFooterBar,
    },
    data() {
        return {
            modificationsInFlight: false,
            detailReRenderKey: 1,

            showModal: false,
            callback: null,
        };
    },
    computed: {
        ...mapGetters( {
            catalogListingCount: 'CatalogListing/listingCount',
            currentCatalogListingIndex: 'CatalogListing/currentCatalogListingIndex',
            hasCurrentCatalogListing: 'hasCurrentCatalogListing',
            hasTitleModifications: 'CatalogListing/Detail/hasTitleModifications',
            hasCatalogListingModifications: 'hasCurrentCatalogListingModifications',
        } ),
        ...mapState( {
            currentTitle: ( state ) => state.currentTitle,
            catalogListings: ( state ) => state.CatalogListing.list,
            catalogListingSearchLoading: ( state ) => state.CatalogListing.inFlight,
            currentCatalogListing: ( state ) => state.currentCatalogListing,
            titleModifications: ( state ) => state.CatalogListing.Detail.titleModifications,
            statusModified: ( state ) => state.TitleGroup.statusModified,
        } ),
        /** @returns { Boolean } */
        hasModifications() {
            return this.hasTitleModifications
                || this.hasCatalogListingModifications
                || this.statusModified;
        },
        /** @returns { Boolean } */
        canGoToPreviousListing() {
            return this.currentCatalogListingIndex > 0;
        },
        /** @returns { Boolean } */
        canGoToNextListing() {
            return this.currentCatalogListingIndex < ( this.catalogListingCount - 1 );
        },
    },
    watch: {
        currentTitle: {
            handler: 'currentTitleHandler',
            immediate: true,
        },
    },
    beforeMount() {
        window.addEventListener( 'beforeunload', this.preventNav );
        this.$once( 'hook:beforeDestroy', () => {
            window.removeEventListener( 'beforeunload', this.preventNav );
        } );
    },
    methods: {
        ...mapActions( {
            modifyListingTitle: 'CatalogListing/modifyListingTitle',
            modifyCatalogListing: 'CatalogListing/modify',
            getCatalogListingById: 'CatalogListing/getById',
            updateCatalogListingWithModifications: 'CatalogListing/refreshItemInListWithCurrentCatalogListing',
            initializeTitleModifications: 'CatalogListing/Detail/initializeTitleModifications',
            getCatalogListingMetaData: 'CatalogListing/Detail/getCatalogListingMetaData',
            refreshTitleGroupStatus: 'TitleGroup/refreshTitleGroupStatus',
            updateGroupStatus: 'TitleGroup/updateGroupStatus',
        } ),

        currentTitleHandler() {
            this.initializeTitleModifications( this.currentTitle );
            this.getCatalogListingMetaData();
            this.detailReRenderKey += 1;
        },

        // This function prevents the browser from navigating while editing.
        preventNav( event ) {
            if ( !this.hasModifications ) return;
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = '';
        },

        showNextListing() {
            if ( this.canGoToNextListing ) {
                const nextListing = this.catalogListings[ this.currentCatalogListingIndex + 1 ];
                this.$router.push( {
                    name: 'TitleResearchCatalogListings',
                    params: { catalogListingId: nextListing.catalogListing.id },
                } );
            } else {
                this.$emit( 'showSummary', 'Completed Catalog Listings' );
            }
        },
        showPreviousListing() {
            if ( this.canGoToPreviousListing ) {
                const previousListing = this.catalogListings[ this.currentCatalogListingIndex - 1 ];
                this.$router.push( {
                    name: 'TitleResearchCatalogListings',
                    params: { catalogListingId: previousListing.catalogListing.id },
                } );
            }
        },

        validateGroupingStatusSaved( callback ) {
            if ( this.statusModified ) {
                this.callback = callback;
                this.showModal = true;
            } else {
                callback();
            }
        },
        async resetTitleGroupingStatus() {
            await this.refreshTitleGroupStatus();
            await this.callback();
        },

        async persistAllChanges() {
            await this.updateGroupStatus();
            await this.callback();
        },

        async saveTitleAndListingModifications() {
            this.modificationsInFlight = true;
            try {
                await this.modifyListingTitle( {
                    title: this.titleModifications,
                } );
                await this.modifyCatalogListing( {
                    catalogListingList: [ this.currentCatalogListing ],
                } );
                await this.getCatalogListingById( {
                    catalogListingId: this.currentCatalogListing.id,
                } );
                // Update the catalog listing in our list that corresponds to our currentCatalogListing
                await this.updateCatalogListingWithModifications();
                // Clear out title titleModifications
                await this.initializeTitleModifications( this.currentTitle );
            } catch ( error ) {
                console.log( 'Error in saveTitleAndListingModifications: ', error );
            } finally {
                this.modificationsInFlight = false;
            }
        },
    },
};
</script>

<style scoped>
.flex-footer-wrapper {
    flex: 1;
}

#footer-modifiers {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 50px;
}

.button-save {
    margin-right: 1rem;
}

.read-only-item {
    margin: 0 1rem;
    letter-spacing: 1px;
}

.read-only-item .read-only-item-text {
    color: #66c0ec;
    font-weight: bold;
    font-size: 14px;
}
</style>
