<script>
import { mapState, mapActions } from 'vuex';

// Utils
import ArrayUtil from '@/utils/ArrayUtil';
import WordCloudBase from '@/components/base/wordcloud/WordcloudBase';

export default {
    name: 'SlingshotLibraries',
    components: { WordCloudBase },
    props: {
        selectedLibraries: {
            type: Array,
            default: () => [],
        },
        titleIsbn: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showSelectableOptions: false,
            optionKey: '',
        };
    },
    computed: {
        ...mapState({
            libraries: (state) => state.GlobalResources.slingshotLibraries,
        }),

        /** @returns { Boolean } */
        hasLibraries() {
            return this.libraries.length > 0;
        },
    },
    methods: {
        ...mapActions({
            addTitleToLibrary: 'Title/LibraryManagement/addTitleToLibrary',
            removeTitleFromLibrary: 'Title/LibraryManagement/removeTitleFromLibrary',
        }),

        isIncluded(library) {
            return ArrayUtil.includes(this.selectedLibraries, library, 'name');
        },

        toggleSelection(library) {
            const selectedItems = [...this.selectedLibraries];
            if (this.isIncluded(library)) {
                ArrayUtil.remove(selectedItems, library, 'name');
            } else {
                selectedItems.push(library);
            }

            const selectedLibraryId = this.selectedLibraries.map((item) => item.id);
            const updatedItemId = selectedItems.map((item) => item.id);

            if (!ArrayUtil.isEqual(selectedLibraryId, updatedItemId)) {
                const payload = {
                    libraryName: library.name,
                    isbn: this.titleIsbn,
                };
                if (this.isIncluded(library)) {
                    this.removeTitleFromLibrary(payload);
                } else {
                    this.addTitleToLibrary(payload);
                }
            }
        },
    },
};
</script>

<template>
    <div>
        <v-hover v-slot="{ hover }">
            <div class="multi-option-base">
                <!-- Heading -->
                <div class="multi-option-base__heading">
                    <h5 class="column-title-heading">Manually-Managed Libraries</h5>
                    <v-btn
                        v-if="!hover"
                        text
                        icon
                        class="multi-option-base__icon"
                        color="accent"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </div>

                <!-- WordCloud -->
                <WordCloudBase
                    v-show="!hover"
                    :itemList="selectedLibraries"
                    displayedKey="friendly"
                />

                <!-- Selectable Options -->
                <div v-if="hasLibraries && hover">
                    <div class="select-include-only">
                        <div id="display-single-list">
                            <ul class="option-list">
                                <li
                                    v-for="(library, index) in libraries"
                                    :key="index"
                                    class="option"
                                    :class="{ included: isIncluded(library) }"
                                    @click="toggleSelection(library)"
                                    @mousedown.prevent
                                >
                                    <v-icon small class="included-designator"> mdi-check </v-icon>
                                    {{ library.friendly }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p v-else-if="!hasLibraries && hover">No Libraries Available</p>
            </div>
        </v-hover>
    </div>
</template>
