<script>
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex';

// Api
import AdoptionApi from '@/api/AdoptionApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'CatalogTable',
    components: { TableBaseFilterable },
    props: {
        hideFilter: {
            type: Boolean,
            required: false,
            default: false,
        },
        listings: {
            type: Array,
            required: true,
        },
        isEditable: {
            type: Boolean,
            required: false,
            default: false,
        },
        showNotes: {
            type: Boolean,
            required: false,
            default: false,
        },
        showMessagingStatus: {
            type: Boolean,
            required: false,
            default: false,
        },
        showOverrides: {
            type: Boolean,
            required: false,
            default: false,
        },
        prefix: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            sharedHeaders: [
                {
                    text: 'Location',
                    value: 'catalogListing.locationName',
                },
                {
                    text: 'Division',
                    value: 'catalogListing.divisionName',
                },
                {
                    text: 'Term',
                    value: 'catalogListing.termName',
                },
                {
                    text: 'Dept.',
                    value: 'catalogListing.department.code',
                },
                {
                    text: 'Course',
                    value: 'catalogListing.course.code',
                },
                {
                    text: 'Section',
                    value: 'catalogListing.section.code',
                },
                {
                    text: 'Section Start',
                    value: 'catalogListing.section.startDate',
                },
                {
                    text: 'Section End',
                    value: 'catalogListing.section.sectionEndDate',
                },
                {
                    text: 'Professor',
                    value: 'catalogListing.professor',
                },
                {
                    text: 'Act. Enroll.',
                    value: 'catalogListing.section.enrollment',
                },
                {
                    text: 'Estimated',
                    value: 'catalogListing.section.estimatedEnrollment',
                },
                {
                    text: 'Capacity',
                    value: 'catalogListing.section.sectionCapacity',
                },
                {
                    text: 'QTP',
                    value: 'catalogListing.qtp',
                },
                {
                    text: 'Req/Opt',
                    value: 'catalogListing.required',
                },

            ],
            FormatUtil,
            filter: '',
            inFlight: false,
            catalogDesignatorFriendlies: {
                NEW_ONLY_CONSUMABLE: 'New Only',
                PRINT_ONLY: 'Print Only',
            },
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),
        ...mapState( {
            currentCatalogListing: ( state ) => state.currentCatalogListing,
        } ),
        headers() {
            const headers = [ ...this.sharedHeaders ];
            if ( this.showNotes ) {
                headers.push( {
                    text: 'Notes',
                    value: 'catalogListing.note',
                } );
            }
            if ( this.showMessagingStatus ) {
                headers.push( {
                    text: 'Messaging Status',
                    value: 'messagingStatus',
                } );
            }
            if ( this.showOverrides ) {
                headers.push( {
                    text: 'Overrides',
                    value: 'overrides',
                } );
            }
            return headers;
        },
        qtp: {
            get() {
                return this.currentCatalogListing.qtp;
            },
            set( qtp ) {
                this.updateCurrentCatalogListing( {
                    key: 'qtp',
                    value: qtp,
                } );
            },
        },
    },
    watch: {
        currentCatalogListing: {
            handler() {
                this.init();
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions( {
            updateCurrentCatalogListing: 'updateCurrentCatalogListing',
        } ),
        init() {
            if ( this.showMessagingStatus ) {
                this.getMessagingStatus();
            }
        },
        async getMessagingStatus() {
            this.inFlight = true;
            try {
                const { data } = await AdoptionApi.getCatalogListingMessagingStatus( {
                    authToken: this.authToken,
                    catalogListingId: this.currentCatalogListing.id,
                } );
                this.messagingStatus = data.adoptionMessagingStatusFriendly;
            } catch ( error ) {
                console.error( 'ERROR in getMessagingStatus: ', error );
            } finally {
                this.inFlight = false;
            }
        },
        /** @param { designator } designator */
        getDesignatorFriendly( designator ) {
            return this.catalogDesignatorFriendlies?.[ designator.name ] ?? designator.name;
        },
    },
};
</script>
<template>
    <TableBaseFilterable itemKey="catalogListing.id" :headers="headers" :items="listings" :hideFilter="hideFilter" :filter.sync="filter">
        <template #[`item.catalogListing.locationName`]="{ item }">
            {{ FormatUtil.locationNameToPrettyName(item.catalogListing.locationName) }}
        </template>
        <template #[`item.catalogListing.department.code`]="{ item }">
            {{ item.catalogListing.department.code || catalogListing.department.id }}
        </template>
        <template #[`item.catalogListing.section.sectionEndDate`]="{ item }">
            {{ item.catalogListing.section.sectionEndDate || 'Not set' }}
        </template>
        <template #[`item.catalogListing.professor`]="{ item }">
            {{ item.catalogListing.professor || 'Not set' }}
        </template>
        <template #[`item.catalogListing.section.enrollment`]="{ item }">
            {{ item.catalogListing.section.enrollment || 'Not set' }}
        </template>
        <template #[`item.catalogListing.section.estimatedEnrollment`]="{ item }">
            {{ item.catalogListing.section.estimatedEnrollment || 'Not set' }}
        </template>
        <template #[`item.catalogListing.section.sectionCapacity`]="{ item }">
            {{ item.catalogListing.section.sectionCapacity || 'Not set' }}
        </template>
        <template #[`item.catalogListing.qtp`]="{ item }">
            <span v-if="isEditable">
                <v-text-field v-model.number="qtp" dense outlined hideDetails type="number"
                    class="small-number-field" />
            </span>
            <span v-else>
                {{ item.catalogListing.qtp }}
            </span>
        </template>
        <template #[`item.designators`]="{ item }">
            <div v-if="item.designators && item.designators.length">

                <div v-for="designator in [...item.designators].sort((a, b) => a.name.localeCompare(b.name))"
                    :key="`${prefix}-${designator.id}`">
                    {{ designator.name }}
                </div>
            </div>
            <div v-else>
                None
            </div>
        </template>
        <template #[`item.catalogListing.catalogListingDesignatorDtos`]="{ item }">
            <div
                v-if="item.catalogListing.catalogListingDesignatorDtos && item.catalogListing.catalogListingDesignatorDtos.length">

                <div v-for="designator in [...item.catalogListing.catalogListingDesignatorDtos].sort((a, b) => a.name.localeCompare(b.name))"
                    :key="`${prefix}-${designator.id}`">
                    {{ designator.name }}
                </div>
            </div>
            <div v-else>
                None
            </div>
        </template>
        <template #[`item.catalogListing.note`]="{ item }">
            {{ item.catalogListing.note || 'No notes' }}
        </template>
        <template #[`item.messagingStatus`]>
            <p v-if="inFlight">...</p>
            <p v-else>{{ messagingStatus }}</p>
        </template>
        <template #[`item.overrides`]="{item}">
            <div v-if="item.catalogListing.effectiveCatalogDesignators && item.catalogListing.effectiveCatalogDesignators.length">
                <div
                    v-for="designator in item.catalogListing.effectiveCatalogDesignators"
                    :key="designator.id"
                    class="override-column"
                >
                    {{ getDesignatorFriendly( designator ) }}
                </div>
            </div>
            <div v-else>None</div>
        </template>
    </TableBaseFilterable>
</template>
<style lang="scss" scoped>
.small-number-field {
    font-size: 12px;
    width: 60px;
}
.override-column {
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
}
</style>
