<template>
    <MultiOptionBase
        :fullItemList="titleDesignators"
        :preSelectedItemList="preSelectedList"
        displayedKey="name"
        heading="Title Designators"
        @changes="changes => $emit( 'changes', changes )"
        @removal="$emit( 'removal', 'designators' )"
    />
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import MultiOptionBase from '@/components/base/multioption/MultiOptionBase';

export default {
    name: 'MultiOptionTitleDesignators',
    components: {
        MultiOptionBase,
    },
    props: {
        preSelectedList: {
            type: Array, // of objects, must have 'id' and 'name'
            required: true,
        },
    },
    computed: {
        ...mapGetters( {
            hasTitleDesignators: 'GlobalResources/hasTitleDesignatorList',
            hasNoTitleDesignators: 'GlobalResources/hasNoTitleDesignators',
        } ),
        ...mapState( {
            titleDesignators: ( state ) => state.GlobalResources.titleDesignatorList,
        } ),
    },
};
</script>

<style scoped>
</style>
