<template>
    <ModalBase
        class="bulk-edit-modal stylized"
        :containerStyleOverride="containerStyle"
        @close="closeModal()"
    >
        <!-- Message Slot -->
        <div
            v-show="errorMessage"
            slot="message"
            class="message"
        >
            {{ errorMessage }}
        </div>
        <!-- Body Slot -->
        <div
            slot="body"
            class="content"
        >
            <div class="content-section">
                <div class="content-section__label">
                    <label>Select a new Status:</label>
                </div>
                <div class="content-section__input">
                    <AutocompleteBase
                        v-model="catalogListingStatus"
                        :items="catalogListingStatusList"
                    />
                </div>
            </div>
            <div class="content-section">
                <div class="content-section__label">
                    <label for="prepended-note">
                        Prepend note to listings:
                    </label>
                </div>
                <div class="content-section__input">
                    <v-text-field
                        id="prepended-note"
                        v-model="prependedNote"
                        outlined
                    />
                </div>
            </div>
            <v-btn
                color="primary"
                class="button-apply"
                :disabled="inFlight"
                :loading="inFlight"
                @click="buttonHandler()"
            >
                Apply to {{ numberOfCatalogListings }} Catalog Listing{{ numberOfCatalogListings > 1 ? 's' : '' }}
            </v-btn>
        </div>
    </ModalBase>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Components
import ModalBase from '@/components/base/modals/ModalBase';
import AutocompleteBase from '@/components/base/dropdowns/AutocompleteBase';

/**
 * @emits 'close'
 * @emits 'editSuccess'
 */
export default {
    name: 'ModalBulkEdit',
    components: {
        ModalBase,
        AutocompleteBase,
    },
    props: {
        selectedCatalogListings: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            numberOfCatalogListings: this.selectedCatalogListings.length,
            containerStyle: {
                width: '440px',
            },
            loaderIconStyles: {
                width: '20px',
                height: '20px',
                'border-width': '2px',
            },

            errorMessage: '',
            inFlight: false,
            catalogListingStatus: null,
            prependedNote: '',
        };
    },
    computed: {
        ...mapState( {
            catalogListingStatusList: ( state ) => state.GlobalResources.catalogListingStatusList,
        } ),

        /** @returns { Array } */
        modifiedCatalogListings() {
            return this.selectedCatalogListings
                .map( ( listing ) => {
                    const updatedListing = {
                        id: listing.id,
                    };
                    if ( this.catalogListingStatus ) {
                        updatedListing.status = this.catalogListingStatus;
                    }
                    if ( this.prependedNote ) {
                        updatedListing.prependToExistingNote = this.prependedNote;
                    }
                    return updatedListing;
                } );
        },
    },
    methods: {
        closeModal() {
            this.$emit( 'close' );
        },

        async buttonHandler() {
            this.inFlight = true;
            this.errorMessage = '';
            try {
                await this.$store.dispatch( 'CatalogListing/modifyList', {
                    catalogListingList: this.modifiedCatalogListings,
                    bubbleErrors: true,
                } );
                this.$emit( 'editSuccess' );
                this.closeModal();
            } catch ( error ) {
                this.errorMessage = error;
            } finally {
                this.inFlight = false;
            }
        },
    },
};
</script>

<style lang="scss">
.bulk-edit-modal {
    .content {
        display: flex;
        flex-flow: column nowrap;
        text-align: left;
        margin: 1rem;
        &-section {
            margin-bottom: 1rem;
        }
    }
    .message {
        padding: .75rem;
        color: red;
        word-break: break-word;
    }
    .button-apply {
        font-weight: bold;
    }
}
</style>
