<template>
    <div class="select-include-only">
        <div
            v-if="showResetButton"
            class="select-include-only__header"
        >
            <div id="quick-select-links">
                <a
                    class="reset-link"
                    @click="reset()"
                >
                    Reset
                </a>
            </div>
        </div>

        <div id="display-single-list">
            <ul class="option-list">
                <li
                    v-for="( option, index ) in options"
                    :key="index"
                    class="option"
                    :class="{ included: isIncluded( option ) }"
                    @click="toggleSelection( option )"
                    @mousedown.prevent
                >
                    <i class="material-icons included-designator">done</i>
                    {{ option[ name ] }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// Utils
import ArrayUtil from '@/utils/ArrayUtil';

export default {
    name: 'SelectIncludeOnly',
    props: {
        options: {
            type: Array,
            required: true,
        },
        preSelectedOptions: {
            type: Array,
            required: false,
            default: () => ( [] ),
        },
        name: {
            type: String,
            required: false,
            default: 'name',
        },
        showResetButton: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            includedOptions: [],
        };
    },
    watch: {
        options: {
            handler: 'initialize',
            immediate: true,
        },
        preSelectedOptions: {
            handler: 'initialize',
            immediate: true,
        },
    },
    methods: {
        initialize() {
            if ( this.options.length > 0 ) {
                this.includedOptions = ArrayUtil.clone( this.preSelectedOptions );
            }
        },
        isIncluded( option ) {
            return ArrayUtil.includes( this.includedOptions, option, this.name );
        },
        toggleSelection( option ) {
            if ( this.isIncluded( option ) ) {
                ArrayUtil.remove( this.includedOptions, option, this.name );
            } else {
                this.includedOptions.push( option );
            }

            this.publishSelectedOptions();
        },
        reset() {
            this.includedOptions = [];
            this.publishSelectedOptions();
        },
        publishSelectedOptions() {
            this.$emit( 'updateOptions', this.includedOptions );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";

.select-include-only {
    &__header {
        display: flex;
        margin: 20px 0;
        justify-content: space-between;
        align-items: center;
    }
    ul, ol{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    #display-single-list .option-list{
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
    }
    .option {
        position: relative;
        font-size: .65rem;
        padding: .4rem .5rem .4rem 1.3rem;
        margin: 3px;
        cursor: pointer;
        border-radius: 7px;
        background-color: #f6f6f6;
        color: #646569;
        letter-spacing: .10rem;
        font-weight: bold;
    }
    .option:hover,
    .option.included:hover{
        opacity: .9;
    }
    .option.included{
        background-color: var(--v-primary-base);
        color: $white;
        border: 1px solid $white;
    }
    .included-designator{
        display: none;
    }
    .option.included .included-designator{
        display: inline-block;
        color: white;
        font-size: 18px;
        text-align: center;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
    }
    #quick-select-links a{
        font-size: .8rem;
        cursor: pointer;
        border: solid 2px white;
        padding: 5px 10px;
        border-radius: 10px;
    }
}

</style>
