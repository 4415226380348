export default {
    openNewTab( url, focus = true ) {
        if ( !url ) {
            console.warn( 'Cannot go to undefined URL' );
        } else {
            // eslint-disable-next-line no-lonely-if
            if ( focus ) {
                const win = window.open( url, '_blank' );
                if ( win ) win.focus();
            } else {
                window.open( url, '_blank' );
            }
        }
    },
};
