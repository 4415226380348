<template>
    <div
        class="multi-option-base"
        @mouseover="mouseOverHandler()"
        @mouseout="mouseOutHandler()"
    >

        <!-- Heading -->
        <div class="multi-option-base__heading">
            <h5
                v-if="hasHeading"
                class="column-title-heading"
            >
                {{ heading }}
            </h5>
            <ClButton
                v-show="!showSelectableOptions"
                class="multi-option-base__icon"
                icon
                color="accent"
            >
                <v-icon small>mdi-pencil</v-icon>
            </ClButton>
        </div>

        <!-- WordCloud -->
        <WordCloudBase
            v-show="!modeIsIncludeExclude && !showSelectableOptions"
            :itemList="localOptionList"
            :displayedKey="displayedKey"
        />

        <!-- Selectable Options -->
        <div v-show="modeIsIncludeExclude || showSelectableOptions">
            <div v-if="hasFullItemList">
                <!-- Include Only -->
                <SelectIncludeOnly
                    v-if="modeIsInclude"
                    :options="fullItemList"
                    :preSelectedOptions="localOptionList"
                    :optionKey="optionKey"
                    :showResetButton="false"
                    :name="displayedKey"
                    @updateOptions="( options ) => updateHandler( options )"
                />
                <!-- Include Exclude -->
                <SelectIncludeExclude
                    v-if="modeIsIncludeExclude"
                    :initialOptions="fullItemList"
                    :preSelectedOptions="preSelectedIncludeExcludeOptions"
                    :optionKey="optionKey"
                    :name="displayedKey"
                    @updateOptions="( inclusiveList, exclusiveList ) => emitIncludeExcludeLists( inclusiveList, exclusiveList )"
                />
            </div>
            <p v-else>Loading...</p>
        </div>

    </div>
</template>

<script>
// Utils
import ArrayUtil from '@/utils/ArrayUtil';

// Components
import SelectIncludeOnly from '@/components/base/multioption/SelectIncludeOnly';
import SelectIncludeExclude from '@/components/base/multioption/SelectIncludeExclude';
import WordCloudBase from '@/components/base/wordcloud/WordcloudBase';

export default {
    name: 'MultiOptionBase',
    components: {
        SelectIncludeOnly,
        SelectIncludeExclude,
        WordCloudBase,
    },
    props: {
        heading: {
            type: String,
            required: false,
            default: '',
        },
        fullItemList: {
            type: Array, // of objects, must have 'id' and [displayedKey]
            required: false,
            default: () => [],
        },
        // This option should be refactored away in favor of preSelectedIncludeExcludeOptions
        // Still included to 1. make current changes non-breaking and 2. Try to keep scope under control
        preSelectedItemList: {
            type: Array, // of objects, must have 'id' and [displayedKey]
            required: false,
            default: () => [],
        },
        preSelectedIncludeExcludeOptions: {
            type: Object,
            required: false,
            default: () => {},
        },
        optionKey: {
            type: String,
            required: false,
            default: '',
        },
        displayedKey: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            required: false,
            default: 'include',
            validator: ( value ) => [ 'include', 'includeExclude' ].includes( value ),
        },
    },
    data() {
        return {
            showSelectableOptions: false,
            localOptionList: [],
            referenceOptionList: [],
        };
    },
    computed: {
        /** @returns { Boolean } */
        hasHeading() {
            return this.heading !== '';
        },

        /** @returns { Boolean } */
        hasFullItemList() {
            return this.fullItemList.length > 0;
        },

        /** @returns { Boolean } */
        modeIsInclude() {
            return this.mode === 'include';
        },
        /** @returns { Boolean } */
        modeIsIncludeExclude() {
            return this.mode === 'includeExclude';
        },
    },
    watch: {
        preSelectedItemList: {
            handler: 'initLocalData',
            immediate: true,
        },
    },
    methods: {
        initLocalData() {
            this.localOptionList = this.preSelectedItemList;
            this.referenceOptionList = this.preSelectedItemList;
        },

        displaySelectableOptions() {
            this.showSelectableOptions = true;
        },
        hideSelectableOptions() {
            this.showSelectableOptions = false;
        },
        mouseOverHandler() {
            if ( !this.modeIsIncludeExclude ) this.displaySelectableOptions();
        },
        mouseOutHandler() {
            if ( !this.modeIsIncludeExclude ) this.hideSelectableOptions();
        },

        emitIncludeExcludeLists( inclusiveList, exclusiveList ) {
            this.$emit( 'includeExclude', inclusiveList, exclusiveList );
        },

        emitChanges( changes ) {
            this.$emit( 'changes', changes );
        },
        emitRemoval( removal ) {
            this.$emit( 'removal', removal );
        },
        updateHandler( updatedOptions ) {
            this.localOptionList = updatedOptions;
            if ( !ArrayUtil.isEqual( this.referenceOptionList.map( ( item ) => item.id ), updatedOptions.map( ( item ) => item.id ) ) ) {
                this.emitChanges( updatedOptions );
            } else {
                this.emitRemoval( updatedOptions );
            }
        },
    },
};
</script>

<style scoped lang="scss">
.multi-option-base {
    &__heading {
        display: flex;
        align-items: center;
    }
    &__icon {
        margin-top: -0.5rem;
    }
}
</style>
