<template>
    <div class="toggleable-content-container">
        <!-- Content Open -->
        <div v-if="showContent">
            <div
                class="title-container"
                @click="toggleContent()"
            >
                <h2>{{ contentTitle }}</h2>
                <i class="material-icons">arrow_drop_down</i>
            </div>

            <div class="slot-content-container">
                <!-- Content Slot -->
                <slot></slot>
            </div>
        </div>
        <!-- Content Closed -->
        <div
            v-else
            class="title-container"
            @click="toggleContent()"
        >
            <h2>{{ contentTitle }}</h2>
            <i class="material-icons">arrow_right</i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ToggleableContent',
    props: {
        contentTitle: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showContent: false,
        };
    },
    methods: {
        toggleContent() {
            this.showContent = !this.showContent;
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/contextualinfo.scss";

.toggleable-content-container {
    padding: 1rem 0;

    .slot-content-container {
        margin-top: 0.5rem;
    }
}

.material-icons {
    color: $coninfo-icon-fg;
    cursor: pointer;
}

.title-container {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;

    h2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .material-icons {
        margin: auto auto auto 0.5rem;
    }
}
</style>
