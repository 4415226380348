<template>
    <div
        class="option-container"
        :class="{ 'is-option-selected': isSelected, 'is-inactive': !isActive }"
    >
        <div class="option-info bottom-padding">
            <!-- Top Row -->
            <div class="top-row">

                <!-- EAN -->
                <div class="isbn">
                    <h3 class="column-title-heading">EAN</h3>
                    <p class="column-title-text-detail">{{ option.title.productCode }}</p>
                </div>

                <!-- Price -->
                <div class="price">
                    <h3 class="column-title-heading">Price</h3>
                    <p class="column-title-text-detail">{{ FormatUtil.toCurrency( option.price ) }}</p>
                </div>

                <!-- Cost -->
                <div class="cost">
                    <h3 class="column-title-heading">Cost</h3>
                    <p class="column-title-text-detail">{{ FormatUtil.toCurrency( option.cost ) }}</p>
                </div>

                <!-- Duration -->
                <div class="duration">
                    <h3 class="column-title-heading">Duration</h3>
                    <p class="column-title-text-detail">{{ displayDuration }}</p>
                </div>

                <!-- Fulfillment Type -->
                <div class="fulfillment-type">
                    <h3 class="column-title-heading">Fulfillment Type</h3>
                    <p class="column-title-text-detail">{{ displayFulfillmentType }}</p>
                </div>

                <!-- Note -->
                <div class="note">
                    <h3 class="column-title-heading">Note</h3>
                    <p class="column-title-text-detail">{{ option.note }}</p>
                </div>

                <!-- Inclusive Access Supplier -->
                <div class="inclusive-access-supplier">
                    <h3 class="column-title-heading">Inclusive Access Supplier</h3>
                    <p class="column-title-text-detail">{{ option.supplier ? option.supplier.name : 'N/A' }}</p>
                </div>
            </div>

            <!-- Bottom Row -->
            <div class="bottom-row">
                <!-- Location -->
                <div
                    v-if="hasLocation"
                    class="location"
                >
                    <h3 class="column-title-heading">Location</h3>
                    <p class="column-title-text-detail">{{ displayLocationName }}</p>
                </div>

                <!-- Division -->
                <div
                    v-if="hasDivision"
                    class="division"
                >
                    <h3 class="column-title-heading">Division</h3>
                    <p class="column-title-text-detail">{{ option.division.name.trim() }}</p>
                </div>

                <!-- Department -->
                <div
                    v-if="hasDepartment"
                    class="department"
                >
                    <h3 class="column-title-heading">Department</h3>
                    <p class="column-title-text-detail">{{ option.departmentCode.trim() }}</p>
                </div>

                <!-- Course -->
                <div
                    v-if="hasCourse"
                    class="course"
                >
                    <h3 class="column-title-heading">Course</h3>
                    <p class="column-title-text-detail">{{ option.courseCode.trim() }}</p>
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div class="button-group">
            <!-- Select / Deselect -->
            <v-btn
                v-if="isSelectable"
                :disabled="!isActive"
                color="primary"
                :class="{ 'can-deselect': canDeselect }"
                @click="emitSelection()"
            >
                <v-icon left>mdi-check</v-icon>
                {{ selectButtonText }}
            </v-btn>

            <!-- Edit -->
            <v-btn
                :disabled="!isActive"
                :title="editButtonTitleText"
                color="info"
                @click="displayModal()"
            >
                <v-icon left>mdi-pencil-outline</v-icon>
                Edit
            </v-btn>

            <!-- Activate / Deactivate -->
            <v-btn
                color="warning"
                @click="changeActiveStatus()"
            >
                <v-icon left>mdi-exclamation</v-icon>
                {{ getActiveStatus }}
            </v-btn>
        </div>

        <!-- Modal -->
        <ModalPriceOption
            v-if="showModal"
            :option="option"
            modalType="Edit"
            @close="hideModal()"
            @refresh="emitRefresh()"
        />
    </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Api
import CatalogPricingApi from '@/api/CatalogPricingApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import ModalPriceOption from '@/components/modals/ModalPriceOption';

/**
 * @emit 'refresh'
 * @emit 'selection' => { priceOption }
 */
export default {
    name: 'PriceOption',
    components: {
        ModalPriceOption,
    },
    props: {
        // A 'price option' object
        option: {
            type: Object,
            required: true,
        },
        selectedId: {
            type: [ Number, null ],
            required: false,
            default: null,
        },
        isSelectable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            FormatUtil,
            showModal: false,
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),

        /** @returns { Boolean } */
        isActive() {
            return !!this.option.active;
        },

        /** @returns { Boolean } */
        isSelected() {
            return !!( this.selectedId && this.selectedId === this.option.id );
        },

        /** @returns { String } */
        displayDuration() {
            return FormatUtil.digitalDurationToFriendly( this.option.duration );
        },

        /** @returns { String } */
        displayFulfillmentType() {
            return this.option.fulfillmentType && this.option.fulfillmentType.friendly
                ? this.option.fulfillmentType.friendly
                : 'N/A';
        },

        /** @returns { String } */
        getActiveStatus() {
            return this.isActive
                ? 'Deactivate'
                : 'Activate';
        },

        /** @returns { Boolean } */
        hasLocation() {
            return !!this.option.location;
        },

        /** @returns { Boolean } */
        hasDivision() {
            return !!this.option.division;
        },

        /** @returns { Boolean } */
        hasDepartment() {
            return !!this.option.departmentCode;
        },

        /** @returns { Boolean } */
        hasCourse() {
            return !!this.option.courseCode;
        },

        /** @returns { String } */
        displayLocationName() {
            return this.hasLocation
                ? FormatUtil.locationNameFriendly( this.option.location.name )
                : '';
        },

        /** @returns { String } */
        selectButtonText() {
            return this.isSelected
                ? 'Deselect'
                : 'Select';
        },
        /** @returns { Boolean } */
        canDeselect() {
            return this.selectButtonText === 'Deselect';
        },

        /** @returns { String } */
        editButtonTitleText() {
            return this.isActive
                ? 'Edit'
                : 'Cannot Edit while Inactive';
        },
    },
    methods: {
        displayModal() {
            this.showModal = true;
        },
        hideModal() {
            this.showModal = false;
        },

        emitRefresh() {
            this.$emit( 'refresh' );
        },
        emitSelection() {
            this.$emit( 'selection', this.option );
        },

        async changeActiveStatus() {
            try {
                await CatalogPricingApi.modify( {
                    authToken: this.authToken,
                    catalogPricingEntry: {
                        id: this.option.id,
                        active: !this.isActive,
                    },
                } );
                if ( this.isSelected ) {
                    this.emitSelection();
                }
            } catch ( error ) {
                console.log( 'Error changing catalog price entry active status: ', error );
            } finally {
                this.emitRefresh();
            }
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/pricing.scss";

    .is-inactive {
        background-color: $light-gray;
    }

    /* Container */
    .option-container {
        display: grid;
        grid-template-columns: 1fr 125px;
        gap: 1rem;
        border-radius: 5px;
        padding: 1rem;
        margin-top: 1rem;
        box-shadow: $material-shadow-1;
        justify-content: space-between;

        &:hover {
            box-shadow: $material-shadow-2;
            transition: $material-shadow-hover;
        }

        &.is-option-selected {
            border-bottom: 3px solid $option-selected-border;
            background-color: $option-selected-bg;

            &:hover {
                box-shadow: $material-shadow-2;
                transition: $material-shadow-hover;
            }
        }
    }

    /* Option Info Rows */
    .option-info {
        display: flex;
        flex-direction: column;

        p {
            margin-right: .25rem;
        }

        /* Top Row */
        .top-row {
            display: flex;
            flex-flow: wrap;
            justify-content: flex-start;
            gap: 1rem 2rem;
            .inclusive-access-supplier {
                min-width: 300px;
                max-width: 500px;
            }
        }

        /* Bottom Row */
        .bottom-row {
            margin-top: 3rem;
            display: flex;
            flex-flow: wrap;
            justify-content: flex-start;
            gap: 1rem 2rem;
        }
    }

    /* Button Group */
    .button-group {
        >button {
            width: 125px;
            margin-bottom: 0.5rem;
        }

        .can-deselect {
            background-color: $deselect-button;
        }

    }
</style>
