import { render, staticRenderFns } from "./ToggleableContent.vue?vue&type=template&id=8e8e5384&scoped=true&"
import script from "./ToggleableContent.vue?vue&type=script&lang=js&"
export * from "./ToggleableContent.vue?vue&type=script&lang=js&"
import style0 from "./ToggleableContent.vue?vue&type=style&index=0&id=8e8e5384&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e8e5384",
  null
  
)

export default component.exports