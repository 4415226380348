<template>
    <div class="grid-item box-shadow">
        <div
            class="show-content"
            @click="showFullComponent = !showFullComponent"
        >
            <div class="collapsible-header">
                <h2 class="detail-field-heading">
                    Inclusive Access Pricing Records
                </h2>
                <i
                    class="material-icons"
                    :class="[ showFullComponent ? 'show-content-arrow' : 'hide-inclusive-access' ]"
                >
                    arrow_drop_down
                </i>
                <div class="ia-counts">
                    <div v-if="hasPrices">
                        Active: <span class="ia-counts-bold"> {{ activeCount }} </span>
                        | Total: <span class="ia-counts-bold"> {{ priceCount }} </span>
                    </div>
                    <div v-else>
                        No Inclusive Access Pricing Records
                    </div>
                </div>
            </div>
        </div>

        <!-- Open Content -->
        <div v-if="showFullComponent">
            <!-- Loader Icon -->
            <LoaderIconSmall v-if="inFlight"/>
            <!-- Price Options -->
            <div v-else>

                <!-- IA Pricing Checkbox -->
                <div class="ia-button-group-container">
                    <div
                        v-if="selectablePrices"
                        :class="{ 'checkbox-ia-is-active': iaActiveFlag }"
                        class="ia-active-cb"
                        @click="toggleIaCheckBox"
                    >
                        <input
                            id="iaCheckbox"
                            type="checkbox"
                            :value="iaActiveFlag"
                            :checked="iaActiveFlag"
                        />
                        Use IA Pricing
                    </div>

                    <!-- Fulfillment Type -->
                    <div class="fulfillment-type-container">
                        <label
                            for="dropdown-fulfillment-types"
                            :class="{ 'disabled-text': !iaActive }"
                        >
                            Fulfillment Type
                        </label>
                        <DropdownFulfillmentTypes
                            id="dropdown-fulfillment-types"
                            :key="hackyDisplayKey"
                            v-model="fulfillmentType"
                            :disabled="!iaActiveFlag"
                            @change="setFulfillmentType"
                        />
                    </div>

                    <!-- Add IA Price Modal -->
                    <v-btn
                        class="add-button"
                        color="primary"
                        @click="showModal = true"
                    >
                        <v-icon left>mdi-plus</v-icon>
                        Add
                    </v-btn>
                </div>

                <PriceOption
                    v-for="option in priceList"
                    :key="option.id"
                    :option="option"
                    :isSelectable="selectablePrices"
                    :selectedId="selectedPriceOption.id"
                    @refresh="getPricing()"
                    @selection="( selection ) => setInclusiveAccess( selection )"
                />
            </div>

            <!-- Add Modal -->
            <ModalPriceOption
                v-if="showModal"
                modalType="Create"
                :titleId="title.id"
                :productCode="title.productCode"
                @close="showModal = false"
                @refresh="getPricing()"
            />
        </div>
    </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex';

// Components
import DropdownFulfillmentTypes from '@/components/dropdowns/DropdownFulfillmentTypes';
import LoaderIconSmall from '@/components/base/loaders/LoaderIconSmall';
import ModalPriceOption from '@/components/modals/ModalPriceOption';
import PriceOption from './inclusiveAccessPrices/PriceOption';

const defaults = {
    fulfillmentType: () => ( {
        id: null,
        name: null,
        friendly: null,
    } ),
    selectedPriceOption: () => ( {
        id: null,
    } ),
};

export default {
    name: 'InclusiveAccessPrices',
    components: {
        DropdownFulfillmentTypes,
        LoaderIconSmall,
        ModalPriceOption,
        PriceOption,
    },
    props: {
        /** @type { title } */
        title: {
            type: Object,
            required: true,
        },

        // iaActive and selectablePrices should only
        // be used from the catalog listing side
        iaActive: {
            type: Boolean,
            required: false,
        },
        selectablePrices: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showFullComponent: false,
            priceList: [],
            inFlight: false,
            showModal: false,

            // Model binding
            selectedPriceOption: defaults.selectedPriceOption(),
            iaActiveFlag: false,
            fulfillmentType: defaults.fulfillmentType(),

            // Used to redraw the FulfillmentType dropdown when iaActiveFlag is toggled
            hackyDisplayKey: 1,
        };
    },
    computed: {
        ...mapGetters( {
            hasCurrentCatalogListing: 'hasCurrentCatalogListing',
        } ),
        ...mapState( {
            currentCatalogListing: ( state ) => state.currentCatalogListing,
        } ),

        /** @returns { Number } */
        priceCount() {
            return this.priceList.length;
        },
        /** @returns { Boolean } */
        hasPrices() {
            return this.priceCount > 0;
        },
        /** @returns { Number } */
        activeCount() {
            return this.hasPrices
                ? this.priceList.filter( ( item ) => item.active ).length
                : 0;
        },
    },
    watch: {
        title: {
            handler: 'init',
            immediate: true,
            deep: true,
        },
        currentCatalogListing: {
            handler: 'updateLocalModels',
            immediate: true,
            deep: true,
        },
        iaActiveFlag() {
            this.hackyDisplayKey += 1;
        },
    },
    methods: {
        ...mapActions( {
            getInclusiveAccessPricing: 'CatalogListing/getInclusiveAccessPricing',
        } ),

        async init() {
            await this.getPricing();
        },

        updateLocalModels() {
            if ( this.hasCurrentCatalogListing ) {
                this.selectedPriceOption = this.currentCatalogListing.catalogPricing;
                this.fulfillmentType = this.currentCatalogListing.fulfillmentType;
            }
            this.iaActiveFlag = !!(
                this.iaActive
                || ( this.selectedPriceOption.id !== null )
                || ( this.selectedPriceOption.id > 0 )
            );
        },

        emitChanges( changes ) {
            this.$emit( 'changes', changes );
        },
        emitRemoval( removal ) {
            this.$emit( 'removal', removal );
        },
        emittableData() {
            return {
                ia: this.iaActiveFlag,
                catalogPricing: this.selectedPriceOption,
                fulfillmentType: this.fulfillmentType,
            };
        },
        setEmittableData( iaActiveFlag, selectedPriceOption, fulfillmentType ) {
            this.iaActiveFlag = iaActiveFlag;
            this.selectedPriceOption = selectedPriceOption;
            this.fulfillmentType = fulfillmentType;
        },

        setInclusiveAccess( selection ) {
            // If we already have this price option selected, unselect it and emit
            if ( this.selectedPriceOption.id === selection.id ) {
                this.setEmittableData( false, defaults.selectedPriceOption(), defaults.fulfillmentType() );
                this.emitRemoval( this.emittableData() );
            } else {
                this.setEmittableData( true, selection, selection.fulfillmentType );
                this.emitChanges( this.emittableData() );
            }
        },
        toggleIaCheckBox() {
            // Toggle the ia flag, reset others to default values
            this.setEmittableData( !this.iaActiveFlag, defaults.selectedPriceOption(), defaults.fulfillmentType() );
            this.emitChanges( this.emittableData() );
        },
        setFulfillmentType() {
            this.emitChanges( this.emittableData() );
        },

        async getPricing() {
            this.inFlight = true;
            this.priceList = [];
            try {
                this.priceList = await this.getInclusiveAccessPricing(
                    this.selectablePrices
                        ? { catalogListingId: this.currentCatalogListing?.id ?? null }
                        : { titleId: this.title?.id ?? null },
                );
            } finally {
                this.inFlight = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/inclusiveaccess.scss";

h2{
    font-variant-caps: all-small-caps;
    float: left;
    margin: 0 20px 0 0;
}

.grid-item{
    border-radius: 2px;
    padding: 1rem;
}

.disabled-text {
    color: grey;
}

.ia-button-group-container {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;

    .ia-active-cb {
        margin-left: 0.5rem;
        cursor: pointer;
        border-bottom: 2px solid transparent;

        &.checkbox-ia-is-active {
            border-bottom: 2px solid $edit-blue;
        }

        input {
            cursor: pointer;
        }
    }

    .fulfillment-type-container {
        display: flex;
        align-items: center;
        margin-left: 2rem;
    }

    .add-button{
        margin-left: auto;
        margin-right: 1rem;
    }
}

.material-icons {
    margin: auto 0;
    cursor: pointer;
}

.hide-inclusive-access {
    transform: rotate(-90deg);
    color: $ia-access-hide-fg;
}

.show-content {
    cursor: pointer;

    .show-content-arrow {
        color: $ia-access-show-fg;
    }

    .collapsible-header {
        display: flex;
    }

    .ia-counts {
        margin-left: auto;
        font-variant-caps: all-small-caps;
    }
    .ia-counts-bold {
        font-weight: bold;
    }
}

#dropdown-fulfillment-types {
    margin-left: .5rem
}
</style>
