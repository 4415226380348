<template>
    <div class="select-include-exclude">
        <div
            v-if="showResetButton"
            class="select-include-exclude__header"
        >
            <div id="quick-select-links">
                <a
                    class="reset-link"
                    @click="reset()"
                >
                    Reset
                </a>
            </div>
        </div>

        <div
            id="display-separately"
            v-if="showOptionsSeparately"
        >
            <ul class="option-list">
                <li
                    v-for="( option, index ) in includedOptions"
                    :key="index"
                    class="option included"
                    @click="biToggleSelection( option )"
                    @mousedown.prevent
                >
                    {{ getFormattedDesignatorName( option[ name ] ) }}
                </li>
            </ul>
            <ul class="option-list">
                <li
                    v-for="( option, index ) in excludedOptions"
                    :key="index"
                    class="option excluded"
                    @click="biToggleSelection( option )"
                    @mousedown.prevent
                >
                    {{ getFormattedDesignatorName( option[ name ] ) }}
                </li>
            </ul>
        </div>
        <div
            id="display-single-list"
            v-else
        >
            <ul class="option-list">
                <li
                    v-for="( option, index ) in initialOptions"
                    :key="index"
                    class="option"
                    :class="{ included: isIncluded( option ), excluded: isExcluded( option ) }"
                    @click="triToggleSelection( option )"
                    @mousedown.prevent
                >
                    {{ getFormattedDesignatorName( option[ name ] ) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// Utils
import ArrayUtil from '@/utils/ArrayUtil';

export default {
    name: 'SelectIncludeExclude',
    props: {
        initialOptions: { // list of options
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: false,
            default: 'name',
        },
        optionKey: {
            type: String,
            required: false,
            default: '',
        },
        preSelectedOptions: {
            type: Object,
            required: false,
            default: () => ( { include: [], exclude: [] } ),
        },
        showResetButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        showOptionsSeparately: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            includedOptions: [],
            excludedOptions: [],
        };
    },
    watch: {
        initialOptions() {
            this.options = this.getInitialOptionsClone();
            if ( this.options.length === 0 ) {
                this.reset();
            } else {
                this.initializeSelections();
            }
        },
    },
    beforeMount() {
        this.initializeSelections();
    },
    methods: {
        /**
         * Returns a formatted title designator string
         * @param { String } designator - example: "ACCESS_CODE"
         * @returns { String } - example: "access code"
         */
        getFormattedDesignatorName( designator ) {
            return designator
                .replace( /_/g, ' ' )
                .toLowerCase();
        },
        getInitialOptionsClone() {
            return ArrayUtil.clone( this.initialOptions );
        },
        initializeSelections() {
            this.includedOptions = [];
            this.excludedOptions = [];
            if ( this.preSelectedOptions.include ) {
                this.preSelectedOptions.include.forEach( ( includedOption ) => {
                    this.includedOptions.push( includedOption );
                    ArrayUtil.remove( this.excludedOptions, includedOption, this.optionKey );
                } );
            }
            if ( this.preSelectedOptions.exclude ) {
                this.preSelectedOptions.exclude.forEach( ( excludedOption ) => {
                    this.excludedOptions.push( excludedOption );
                    ArrayUtil.remove( this.includedOptions, excludedOption, this.optionKey );
                } );
            }
            this.publishSelectedOptions();
        },
        isIncluded( option ) {
            if ( !this.optionKey ) {
                return this.includedOptions.indexOf( option ) !== -1;
            }
            return this.includedOptions.findIndex(
                ( includedOption ) => includedOption[ this.optionKey ] === option[ this.optionKey ],
            ) !== -1;
        },
        isExcluded( option ) {
            if ( !this.optionKey ) {
                return this.excludedOptions.indexOf( option ) !== -1;
            }
            return this.excludedOptions.findIndex(
                ( excludedOption ) => excludedOption[ this.optionKey ] === option[ this.optionKey ],
            ) !== -1;
        },
        reset() {
            this.includedOptions = [];
            this.excludedOptions = [];
            this.publishSelectedOptions();
        },

        publishSelectedOptions() {
            this.$emit( 'updateOptions', this.includedOptions, this.excludedOptions );
        },

        triToggleSelection( option ) {
            if ( this.isIncluded( option ) ) {
                this.excludedOptions.push( option );
                ArrayUtil.remove( this.includedOptions, option, this.optionKey );
            } else if ( this.isExcluded( option ) ) {
                ArrayUtil.remove( this.includedOptions, option, this.optionKey );
                ArrayUtil.remove( this.excludedOptions, option, this.optionKey );
            } else {
                this.includedOptions.push( option );
                ArrayUtil.remove( this.excludedOptions, option, this.optionKey );
            }

            this.publishSelectedOptions();
        },

        biToggleSelection( option ) {
            if ( this.isIncluded( option ) ) {
                this.excludedOptions.push( option );
                ArrayUtil.remove( this.includedOptions, option, this.optionKey );
            } else {
                this.includedOptions.push( option );
                ArrayUtil.remove( this.excludedOptions, option, this.optionKey );
            }

            this.publishSelectedOptions();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";

$brown-red: #8a2d1c;
$dark-lime-green: #1C8A2B;

.select-include-exclude {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: right;
        margin-top: -50px;
    }
    ul{
        list-style-type: none;
        margin: 1.5rem 0;
        padding: 0;
    }
    #display-single-list .option-list{
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
    }
    #display-separately{
        display: flex;
    }
    #display-separately .option-list{
        flex:1;
    }
    .option {
        position: relative;
        font-size: .65rem;
        padding: .4rem 1.3rem .4rem .5rem;
        margin: 3px;
        cursor: pointer;
        border-radius: 7px;
        background-color: $white;
        color: var(--v-primary-base);
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: capitalize;
        border: solid 1px $light-gray;
    }
    .option:hover,
    .option.included:hover,
    .option.excluded:hover{
        opacity: .9;
    }
    .option.included{
        background-color: $dark-lime-green;
        color: $white;
        border: 1px solid $dark-lime-green;
    }
    .option.included:after {
        content:"+";
        color: white;
        font-size: 18px;
        width: 20px;
        text-align: center;
        position: absolute;
        right: 0;
        top: 50%;
        line-height: 0;
    }

    .option.excluded{
        background-color: $brown-red;
        color: $white;
        border: $brown-red 1px solid;
        margin: 2px;
    }
    .option.excluded:after {
        content:"-";
        color: $white;
        font-size: 18px;
        width: 20px;
        text-align: center;
        position: absolute;
        right: 0;
        top: 42%;
        transform: translateY(-50%);
    }

    #quick-select-links a{
        font-size: .8rem;
        cursor: pointer;
        border: solid 2px $white;
        padding: 5px 10px;
        border-radius: 10px;
    }
}
</style>
