<template>
    <v-autocomplete
        v-model="primarySupplier"
        :aria-required="required"
        :hideDetails="hideDetails"
        :items="primarySupplierList"
        :loading="!primarySupplierList"
        :outlined="outlined"
        backgroundColor="white"
        class="primary-supplier-dropdown"
        clearable
        dense
        itemText="name"
        placeholder="Supplier"
        returnObject
        v-bind="$attrs"
    />
</template>

<script>
// Vuex
import { mapState } from 'vuex';

export default {
    name: 'DropdownPrimarySupplier',
    props: {
        outlined: {
            type: Boolean,
            required: false,
            default: true,
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        /** @type { import('@/typedefs/primarySupplier').primarySupplier } */
        value: {
            type: [ Object, null ],
            default: null,
        },
    },
    computed: {
        ...mapState( {
            primarySupplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),
        /** @returns { import('@/typedefs/primarySupplier').primarySupplier } */
        primarySupplier: {
            get() {
                return this.value;
            },
            set( val ) {
                // Return an empty object instead if val is null,
                // since v-autocomplete throws an error
                // if the v-model is null
                this.$emit( 'input', val ?? {} );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep input {
    // The dense prop adds margin-right: -1rem to this input field.
    // An unfortunate side-effect is that it cuts off long supplier names.
    // This override corrects the issue.
    margin-right: 0 !important;
}
</style>
