<template>
    <div>
        <!-- Reassignment Modal -->
        <ModalReassignSelectedTitles
            v-if="showReassignModal"
            :selectedTitles="getSelectedTitlesFromSelectedListings"
            @close="hideShowReassignModal()"
        />

        <!-- Bulk Edit Modal -->
        <ModalBulkEdit
            v-if="showBulkEditModal"
            :selectedCatalogListings="selectedCatalogListings"
            @close="hideShowBulkEditModal()"
            @editSuccess="clearCheckedItemList()"
        />

        <!-- Top Page Information -->
        <div class="page-info-top result-count-bottom-border">
            {{ catalogListings.length || 0 }} Listings
        </div>

        <div class="scrollable-list">
            <!-- Catalog Listing Summary List -->
            <TableBase
                :data="formattedCatalogListings"
                :columns="tableColumnList"
                :loading="requestInFlight"
                :defaultSort="sortField"
                :defaultSortDirection="sortDirection"
                :rowClass="isLastViewedItemClass"
                :checkedRows.sync="checkedItemList"
                backendSorting
                checkable
                hoverable
                @click="( listing ) => emitListingInfo( listing )"
                @sort="( field, direction ) => onSortHandler( field, direction )"
            >
                <div slot="customPrefixHeading"
                     class="prefix-column-one-heading">
                    <i class="material-icons">info_outline</i>
                </div>

                <template
                    slot="customPrefixContent"
                    slot-scope="{ row }"
                >
                    <ToolTip
                        class="tooltip"
                        :overrideOffsets="{ top: '3.5rem' }"
                    >
                        <i slot="target" class="material-icons">info_outline</i>
                        <div slot="content">

                            <p>Assigned To: {{ row.researcherName || 'Unassigned' }}</p>
                            <p>Last Researched: {{ row.lastResearchedDate || 'Never Researched'}}</p>

                            <ToolTip v-if="row.researcherName">
                                <button slot="target" class="disabled">
                                    Assign To Me
                                </button>
                                <div slot="content">
                                    You can only assign titles that are not already assigned to someone else.
                                </div>
                            </ToolTip>
                            <button
                                v-else
                                class="box-shadow"
                                @click="assignToMeHandler( row )"
                            >
                                Assign To Me
                            </button>

                        </div>
                    </ToolTip>
                </template>
            </TableBase>
        </div>

        <!-- Page Footer Bar -->
        <ThePageFooterBar>
            <div class="footer-contents">
                <div
                    id="number-selected"
                    v-show="selectedItemCount"
                >
                    {{ selectedItemCount }} Selected
                </div>
                <v-btn
                    v-if="titleResearchNotComplete && catalogListings.length > 0"
                    :disabled="requestInFlight"
                    outlined
                    @click="displayShowReassignModal()"
                >
                    Reassign
                </v-btn>
                <v-btn
                    :disabled="disableBulkEdit"
                    color="primary"
                    @click="displayShowBulkEditModal()"
                >
                    Bulk Edit
                </v-btn>
                <v-btn
                    :disabled="disableAutoComplete"
                    color="primary"
                    @click="autoCompleteHandler()"
                >
                    Auto Complete
                </v-btn>
            </div>
        </ThePageFooterBar>

    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Utils
import ArrayUtil from '@/utils/ArrayUtil';
import PageService from '@/services/PageService';

// Components
import ThePageFooterBar from '@/components/ThePageFooterBar';
import TableBase from '@/components/base/table/TableBase';
import ToolTip from '@/components/base/tooltip/ToolTip';
import ModalReassignSelectedTitles from '@/components/modals/ModalReassignSelectedTitles';
import ModalBulkEdit from '@/components/modals/ModalBulkEdit';

export default {
    name: 'SummaryView',
    components: {
        ThePageFooterBar,
        TableBase,
        ToolTip,
        ModalReassignSelectedTitles,
        ModalBulkEdit,
    },
    props: {
        titleResearchNotComplete: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            showReassignModal: false,
            showBulkEditModal: false,

            autoCompleteInFlight: false,

            checkedItemList: [],

            tableColumnList: [
                {
                    label: 'Division',
                    field: 'division',
                    sortable: true,
                },
                {
                    label: 'Term',
                    field: 'term',
                    sortable: true,
                },
                {
                    label: 'Dept',
                    field: 'dept',
                    sortable: true,
                },
                {
                    label: 'Course',
                    field: 'course',
                    sortable: true,
                },
                {
                    label: 'Section',
                    field: 'section',
                    sortable: true,
                },
                {
                    label: 'EAN',
                    field: 'productCode',
                    sortable: true,
                },
                {
                    label: 'Title',
                    field: 'description',
                    sortable: true,
                },
                {
                    label: 'Primary Supplier',
                    field: 'primarySupplier',
                    sortable: true,
                },
                {
                    label: 'Shelf Date',
                    field: 'shelfDate',
                    sortable: true,
                },
                {
                    label: 'Sourcing End Date',
                    field: 'sourcingEndDate',
                    sortable: true,
                },
                {
                    label: 'List Price',
                    field: 'listPrice',
                    sortable: true,
                },
                {
                    label: 'Status',
                    field: 'status',
                    sortable: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters( {
            catalogListingCount: 'CatalogListing/listingCount',
            hasCatalogListings: 'CatalogListing/hasListings',
            formattedCatalogListings: 'CatalogListing/formattedList',
        } ),
        ...mapState( {
            currentCatalogListing: ( state ) => state.currentCatalogListing,
            inFlight: ( state ) => state.CatalogListing.inFlight,
            catalogListings: ( state ) => state.CatalogListing.list,
            sortField: ( state ) => state.CatalogListing.metadata.sortField,
            sortDirection: ( state ) => state.CatalogListing.metadata.sortDirection,
            username: ( state ) => state.User.username,
        } ),

        /** @returns { Boolean } */
        requestInFlight() {
            return this.inFlight || this.autoCompleteInFlight;
        },

        /** @returns { Array } */
        selectedCatalogListings() {
            return ArrayUtil.whereIn( {
                hayStack: this.catalogListings.map( ( listing ) => listing.catalogListing ),
                needleStack: this.checkedItemList.map( ( item ) => item.id ),
            } );
        },
        /** @returns { Number } */
        selectedItemCount() {
            return this.selectedCatalogListings.length;
        },

        /** @returns { Boolean } */
        disableAutoComplete() {
            return this.requestInFlight
                || this.catalogListingCount === 0
                || this.selectedCatalogListings.length === 0;
        },
        /** @returns { Boolean } */
        disableBulkEdit() {
            return this.requestInFlight
                || this.selectedCatalogListings.length === 0;
        },
        /** @returns { Array } */
        getSelectedTitlesFromSelectedListings() {
            return ArrayUtil.unique( this.checkedItemList.map( ( item ) => ( { productCode: item.productCode } ) ) );
        },
    },
    methods: {
        /** @returns { String } */
        isLastViewedItemClass( row ) {
            return row && ( row.id === this.currentCatalogListing.id )
                ? 'last-viewed-item'
                : '';
        },

        hideShowReassignModal() {
            this.showReassignModal = false;
        },
        displayShowReassignModal() {
            this.showReassignModal = true;
        },

        hideShowBulkEditModal() {
            this.showBulkEditModal = false;
        },
        displayShowBulkEditModal() {
            this.showBulkEditModal = true;
        },

        clearCheckedItemList() {
            this.checkedItemList = [];
        },

        onSortHandler( field, direction ) {
            if ( this.sortField !== field ) {
                this.$store.commit( 'CatalogListing/SET_SORT_FIELD', { field } );
            }
            if ( this.sortDirection !== direction ) {
                this.$store.commit( 'CatalogListing/SET_SORT_DIRECTION', { direction } );
            }
            this.$store.dispatch( 'CatalogListing/persistSortedResultsHack' );
        },

        emitListingInfo( catalogListing ) {
            // The catalogListing parameter is a formatted catalog listing from formattedCatalogListings,
            // so we need to get all of that listing's information
            const listingIndex = this.catalogListings.findIndex( ( listing ) => listing.catalogListing.id === catalogListing.id );
            this.$emit( 'clickedListing', this.catalogListings[ listingIndex ] );
        },

        async autoCompleteHandler() {
            this.autoCompleteInFlight = true;
            try {
                await this.$store.dispatch( 'CatalogListing/autocompleteList', {
                    catalogListingList: this.selectedCatalogListings,
                } );
                this.clearCheckedItemList();
            } catch ( error ) {
                console.log( 'Error in autoCompleteHandler: ', error );
            } finally {
                this.autoCompleteInFlight = false;
            }
        },

        async assignToMeHandler( listing ) {
            try {
                await this.$store.dispatch( 'Title/Assignment/assignTitle', {
                    assignee: this.username,
                    productCode: listing.productCode,
                } );
                const route = this.$router.resolve( { name: 'TitleResearchQueue', params: { productCode: listing.productCode } } );
                PageService.openNewTab( route.href, false );
            } catch ( error ) {
                console.log( 'Error in assignToMeHandler: ', error );
            }
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/catalog.scss";

    #number-selected{
        margin-right: 1.5rem;
        font-style: italic;
    }

    .scrollable-list {
        overflow: auto;
        // height: calc(100vh - 200px);
        min-height: 500px;
        max-height: 1500px;
    }

    /* Tooltip header styles */
    .prefix-column-one-heading{
        visibility: hidden;
    }
    .prefix-column-one-heading i{
        font-size: 18px;
        padding: 6px;
    }

    /* Tooltip row styles */
    .tooltip{
        align-self: stretch;
    }
    .tooltip i{
        /*line-height: 48px;*/ /* this is 61 minus top and bottom padding */
        color: $catalog-summary-tooltip-fg;
        font-size: 18px;
        padding: 6px;
        cursor: pointer;
    }

    .tooltip button:hover {
        background-color: $catalog-summary-tooltip-hover-bg;
        box-shadow: none;
    }
    .tooltip-content button{
        background-color: $catalog-summary-tooltip-content-bg;
        color: $catalog-summary-tooltip-content-fg;
        width: 100%;
        border: none;
        border-radius: 8px;
        padding: .4rem;
        font-size: .9rem;
        transition: all .2s ease-in-out;
    }
    .tooltip-content button.disabled{
        opacity: .4;
        cursor: default;
    }
    .footer-contents {
        display: flex;
        align-items: center;
        * {
            margin-right: 1rem;
        }
    }
</style>
