<template>
    <ModalBase class="stylized" :containerStyleOverride="containerStyle" @close="closeModal()">

        <!-- Header -->
        <div slot="header">
            <h2>{{ modalType }}</h2>
        </div>

        <!-- Body -->
        <v-form slot="body">
            <v-container class="pricing-modal-container">
                <v-col class="pricing-modal-container__form-field">
                    <label for="price" class="pricing-modal-container__form-field__label">
                        Price:
                    </label>
                    <InputCurrency id="price" v-model.number="price" />
                </v-col>
                <v-col class="pricing-modal-container__form-field">
                    <label for="cost" class="pricing-modal-container__form-field__label">
                        Cost:
                    </label>
                    <InputCurrency id="cost" v-model.number="cost" />
                </v-col>
                <v-col class="pricing-modal-container__form-field">
                    <label for="duration" class="pricing-modal-container__form-field__label">
                        Duration:
                    </label>
                    <v-radio-group v-model="durationType" hideDetails class="duration-radio-group">
                        <span class="duration-radio-group__radio-item">
                            <v-radio id="number" type="radio" value="number" label="Number of Days"
                                :class="durationTypeIsNumber && 'duration-radio-group__radio-item--days-field-visible'" />
                            <v-text-field id="number-of-days" v-if="durationTypeIsNumber" v-model.number="duration"
                                type="number" dense outlined hideDetails :rules="[durationIsValid]" />
                        </span>
                        <v-radio id="lifetime" class="duration-radio-group__radio-item" type="radio" value="lifetime"
                            label="Lifetime" />
                        <v-radio id="section" class="duration-radio-group__radio-item" type="radio" value="section"
                            label="Section Duration" />
                    </v-radio-group>
                </v-col>
                <!-- Fulfillment Type -->
                <v-col v-if="canChangeFulfillmentType" class="pricing-modal-container__form-field">
                    <label class="pricing-modal-container__form-field__label">Fulfillment Type:</label>
                    <DropdownFulfillmentTypes v-model="fulfillmentType" />
                </v-col>
                <v-col class="pricing-modal-container__form-field">
                    <label for="note" class="pricing-modal-container__form-field__label">
                        Note:
                    </label>
                    <v-textarea id="note" v-model="note" rows="2" outlined dense hideDetails />
                </v-col>
                <v-col class="pricing-modal-container__form-field">
                    <div id="catalog-selection-container">
                        <label for="catalog-selection" class="catalog-selection-detail">
                            Location / Division / Department / Course
                            <span class="small-italics">( Optional )</span>
                            <span class="change-button"><a v-if="!edit" role="button"
                                    @click="catalog = {}; edit = true;">Change</a></span>
                        </label>
                        <div v-if="option && option.location && option.location.name && !edit">
                            <span>{{ FormatUtil.locationNameFriendly(option.location.name) }}</span>
                            <span v-if="option.division && option.division.name"> / {{ option.division.name.trim() }} </span>
                            <span v-if="option.departmentCode"> / {{ option.departmentCode.trim() }} </span>
                            <span v-if="option.courseCode"> / {{ option.courseCode.trim() }} </span>
                        </div>
                        <BreadcrumbBase v-else v-model="catalog" class="catalog-selection"
                            :items="breadcrumbItems" @selected="(item) => catalog = item.breadcrumb" />
                    </div>
                </v-col>

                <!-- Inclusive Access Supplier -->
                <v-col class="pricing-modal-container__form-field">
                    <label for="supplier" class="pricing-modal-container__form-field__label">
                        Supplier:
                    </label>
                    <v-autocomplete id="supplier" v-model="supplier" :items="supplierList"
                        itemText="name" itemValue="id" dense outlined
                        placeholder="Select a Supplier" returnObject autoSelectFirst
                        :rules="[(v) => !!v || 'Supplier is required']" />
                </v-col>

            </v-container>

            <!-- Button Group -->
            <div class="button-group">

                <!-- Cancel Button -->
                <v-btn class="button-cancel" :disabled="inFlight" outlined @click="closeModal()">
                    Cancel
                </v-btn>
                <!-- Save Button -->

                <v-btn class="button-save" color="primary" :disabled="inFlight" @click="saveHandler()">
                    Save
                </v-btn>

            </div>

        </v-form>

    </ModalBase>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Api
import CatalogPricingApi from '@/api/CatalogPricingApi';

// Utils
import FormUtil from '@/utils/FormUtil';
import FormatUtil from '@/utils/FormatUtil';
import ObjectUtil from '@/utils/ObjectUtil';

// Components
import DropdownFulfillmentTypes from '@/components/dropdowns/DropdownFulfillmentTypes';
import ModalBase from '@/components/base/modals/ModalBase';
import BreadcrumbBase from '@/components/base/breadcrumbs/BreadcrumbBase';
import InputCurrency from '@/components/base/inputs/InputCurrency';

const durationTypeMap = {
    number: 'number',
    lifetime: 'lifetime',
    section: 'section',
};

export default {
    name: 'ModalPriceOption',
    components: {
        DropdownFulfillmentTypes,
        ModalBase,
        BreadcrumbBase,
        InputCurrency,
    },
    props: {
        modalType: {
            type: String,
            required: true,
            validator: ( value ) => [ 'Edit', 'Create' ].includes( value ),
        },
        // A 'price option' object
        option: {
            type: Object,
            required: false,
        },
        // titleId and product code are required in tandem
        titleId: {
            type: Number,
            required: false,
        },
        productCode: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            isbn: '',
            cost: 0,
            price: 0,
            duration: 0,
            defaultDuration: 120,
            durationType: '',
            fulfillmentType: {},
            note: '',
            supplier: this.option?.supplier ?? null,
            catalog: {},
            inFlight: false,
            containerStyle: {
                width: '600px',
            },
            FormatUtil,
            edit: false,
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),
        ...mapState( {
            locationList: ( state ) => state.GlobalResources.locationList,
            supplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),

        /** @returns { Boolean } */
        isEdit() {
            return this.modalType === 'Edit';
        },
        /** @returns { Boolean } */
        isCreate() {
            return !this.isEdit;
        },

        /** @returns { Boolean } */
        canChangeFulfillmentType() {
            return this.isCreate;
        },
        /** @returns { Boolean } */
        hasSelectedFulfillmentType() {
            return ObjectUtil.isNotEmpty( this.fulfillmentType );
        },

        /** @returns { Boolean } */
        durationIsValid() {
            return ( typeof this.duration === 'number'
                && ( this.duration === -1 || this.duration >= 0 ) );
        },
        /** @returns { Boolean } */
        durationTypeIsNumber() {
            return this.durationType === durationTypeMap.number;
        },

        /** @returns { Number } */
        inferredDuration() {
            const map = {
                number: this.duration,
                lifetime: -1,
                section: 0,
            };
            return map[ this.durationType ];
        },

        /** @returns { Boolean } */
        priceIsValid() {
            return typeof this.price === 'number'
                && this.price > 0;
        },
        /** @returns { Boolean } */
        costIsValid() {
            return typeof this.cost === 'number'
                && this.cost > 0;
        },
        breadcrumbItems() {
            return [
                {
                    placeholder: 'Location',
                    displayedKey: ( location ) => FormatUtil.locationNameToPrettyName( location.name ),
                    actionName: '',
                    childOf: [],
                    list: this.locationList,
                    id: 'location',
                },
                {
                    placeholder: 'Division',
                    displayedKey: 'name',
                    actionName: 'CatalogListing/getDivisionList',
                    childOf: [ 'location' ],
                    list: [],
                    id: 'division',
                },
                {
                    placeholder: 'Department',
                    displayedKey: '',
                    actionName: 'CatalogListing/getDepartmentList',
                    childOf: [ 'division' ],
                    list: [],
                    id: 'department',
                },
                {
                    placeholder: 'Course',
                    displayedKey: '',
                    actionName: 'CatalogListing/getCourseListByDepartmentAndDivision',
                    childOf: [ 'department', 'division' ],
                    list: [],
                    id: 'course',
                },
            ];
        },
    },
    watch: {
        durationType() {
            if ( this.option ) {
                if ( this.durationType === 'section' && this.option.duration === 0 ) {
                    this.duration = this.defaultDuration;
                }
                if ( this.durationType === 'lifetime' && this.option.duration === -1 ) {
                    this.duration = this.defaultDuration;
                }
            }
        },
    },
    mounted() {
        this.initializeData();
    },
    methods: {
        initializeData() {
            if ( this.option ) {
                this.isbn = this.option.title.productCode;
                this.cost = this.option.cost;
                this.price = this.option.price;
                this.duration = this.option.duration;
                this.note = this.option.note;
                this.catalog.location = this.option.location;
                this.catalog.division = this.option.division;
                this.catalog.department = this.option.departmentCode;
                this.catalog.course = this.option.courseCode;
            }
            if ( this.productCode ) {
                this.isbn = this.productCode;
            }

            // Set durationType
            if ( this.duration === -1 ) {
                this.durationType = durationTypeMap.lifetime;
            } else if ( this.isCreate || this.duration === 0 ) {
                this.durationType = durationTypeMap.section;
                if ( this.isCreate ) this.duration = this.defaultDuration;
            } else {
                this.durationType = durationTypeMap.number;
            }
        },

        closeModal() {
            this.$emit( 'close' );
        },

        saveHandler() {
            // Has valid duration
            if ( this.durationIsValid ) {
                // Price or Cost is invalid, get confirmation
                if ( !this.priceIsValid || !this.costIsValid ) {
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    if ( !confirm( 'The cost or price is set to $0. Are you sure you want to create this IA pricing record?' ) ) {
                        // return to modal
                        return;
                    }
                }

                // Cost is greater than Price, get confirmation
                if ( this.cost > this.price ) {
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    if ( !confirm( "The cost is more than the item's price. Are you sure you want to create this IA pricing record?" ) ) {
                        // return to modal
                        return;
                    }
                }

                if ( !this.supplier ) {
                    // eslint-disable-next-line no-alert
                    alert( 'You must select a Supplier' );
                    // return to modal
                    return;
                }

                if ( this.isCreate ) {
                    if ( !this.hasSelectedFulfillmentType ) {
                        // eslint-disable-next-line no-alert
                        alert( 'You must select a Fulfillment Type' );
                        // return to modal
                        return;
                    }
                }

                // If confirmed || no issue, save
                if ( this.isEdit ) {
                    this.saveEdits();
                } else {
                    this.createPriceOption();
                }
            }
        },

        async createPriceOption() {
            try {
                this.inFlight = true;
                await CatalogPricingApi.create( {
                    authToken: this.authToken,
                    catalogPricingEntry: {
                        active: true,
                        price: this.price,
                        cost: this.cost,
                        duration: this.inferredDuration,
                        note: this.note.trim(),
                        title: {
                            id: this.titleId,
                            productCode: this.isbn,
                        },
                        catalogPricingType: {
                            id: 1,
                            name: 'ia',
                            friendly: 'IA',
                        },
                        fulfillmentType: this.fulfillmentType,
                        ...FormUtil.insertIf( this.catalog.location, {
                            location: this.catalog.location,
                        } ),
                        ...FormUtil.insertIf( this.catalog.division, {
                            division: this.catalog.division,
                        } ),
                        ...FormUtil.insertIf( this.catalog.department, {
                            departmentCode: this.catalog.department,
                        } ),
                        ...FormUtil.insertIf( this.catalog.course, {
                            courseCode: this.catalog.course,
                        } ),
                        supplier: this.supplier,
                    },
                } );
            } catch ( error ) {
                console.error( 'Error creating new catalogPriceEntry: ', error );
            } finally {
                this.inFlight = false;
                this.$emit( 'refresh' );
                this.closeModal();
            }
        },

        async saveEdits() {
            try {
                this.inFlight = true;
                await CatalogPricingApi.modify( {
                    authToken: this.authToken,
                    catalogPricingEntry: {
                        id: this.option.id,
                        price: this.price,
                        cost: this.cost,
                        duration: this.inferredDuration,
                        note: this.note === null ? '' : this.note.trim(),
                        title: {
                            id: this.option.title.id,
                            productCode: this.isbn,
                        },
                        location: this.catalog.location ?? { id: null },
                        division: this.catalog.division ?? { id: null },
                        departmentCode: this.catalog.department ?? '',
                        courseCode: this.catalog.course ?? '',
                        supplier: this.supplier,
                    },
                } );
            } catch ( error ) {
                console.error( 'Error saving catalogPriceEntry changes: ', error );
            } finally {
                this.inFlight = false;
                this.$emit( 'refresh' );
                this.closeModal();
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/pricing.scss";

.pricing-modal-container {
    text-align: left;
    padding-top: 0;

    &__form-field {
        display: flex;
        align-items: top;

        &__label {
            margin-right: 1rem;
            margin-top: .25rem;
            width: 8rem;
        }

        .duration-radio-group {
            margin-top: 0;
            padding-top: 0;

            &__radio-item {
                display: flex;
                align-items: center;
                height: 2.5rem;
                // Mandatory use of important to override vuetify radio group
                margin-bottom: 0 !important;

                .v-radio:not(:last-child):not(:only-child) {
                    margin-bottom: 0
                }

                &--days-field-visible {
                    margin-right: 1rem;
                }
            }
        }
    }
}

.button-group {
    display: flex;
    justify-content: flex-end;

    * {
        margin-right: 1rem;
    }
}

.small-italics {
    display: inline-block;
    margin-left: 0.5rem;
    font-size: small;
    font-style: italic;
}

.change-button {
    font-size: small;
    margin-left: 0.5rem;
}
</style>
