<script>
import { mapGetters } from 'vuex';

// Utils
import ArrayUtil from '@/utils/ArrayUtil';
import WordCloudBase from '@/components/base/wordcloud/WordcloudBase';

export default {
    name: 'CatalogListingDesignators',
    components: { WordCloudBase },
    props: {
        preSelectedList: {
            type: Array, // of objects, must have 'id' and 'name'
            required: true,
        },
    },
    data() {
        return {
            showSelectableOptions: false,
            optionKey: '',
        };
    },
    computed: {
        ...mapGetters({
            catalogListingDesignators: 'GlobalResources/catalogListingDesignators',
        }),
        /** @returns { Boolean } */
        hasFullItemList() {
            return this.catalogListingDesignators.length > 0;
        },
    },
    methods: {
        isIncluded(option) {
            return ArrayUtil.includes(this.preSelectedList, option, 'name');
        },
        toggleSelection(option) {
            const selectedItems = [...this.preSelectedList];
            if (this.isIncluded(option)) {
                ArrayUtil.remove(selectedItems, option, 'name');
            } else {
                selectedItems.push(option);
            }

            const preSelectedItemId = this.preSelectedList.map((item) => item.id);
            const updatedItemId = selectedItems.map((item) => item.id);

            if (!ArrayUtil.isEqual(preSelectedItemId, updatedItemId)) {
                this.$emit('changes', {
                    key: 'catalogListingDesignatorDtos',
                    value: selectedItems,
                });
            }
        },
    },
};
</script>

<template>
    <div>
        <v-hover v-slot="{ hover }">
            <div class="multi-option-base">
                <!-- Heading -->
                <div class="multi-option-base__heading">
                    <h5 class="column-title-heading">Catalog Listing Designators</h5>
                    <v-btn v-if="!hover" text icon class="multi-option-base__icon" color="accent">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </div>

                <!-- WordCloud -->
                <WordCloudBase
                    v-show="!hover"
                    :itemList="preSelectedList"
                    displayedKey="friendly"
                />

                <!-- Selectable Options -->
                <div v-if="hasFullItemList && hover">
                    <div class="select-include-only">
                        <div id="display-single-list">
                            <ul class="option-list">
                                <li
                                    v-for="(option, index) in catalogListingDesignators"
                                    :key="index"
                                    class="option"
                                    :class="{ included: isIncluded(option) }"
                                    @click="toggleSelection(option)"
                                    @mousedown.prevent
                                >
                                    <v-icon small class="included-designator"> mdi-check </v-icon>
                                    {{ option.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p v-else-if="!hasFullItemList && hover">Loading...</p>
            </div>
        </v-hover>
    </div>
</template>
