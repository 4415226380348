<template>
    <v-checkbox
        :id="id"
        v-model="model"
        v-bind="$attrs"
        :dense="dense"
        :dark="dark"
        :hideDetails="hideDetails"
        :offIcon="dark ? 'mdi-checkbox-blank' : 'mdi-checkbox-blank-outline'"
        onIcon="mdi-checkbox-marked-outline"
        v-on="$listeners"
    />
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        dark: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: Boolean,
            default: true,
        },
        id: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
};
</script>
