<template>
    <div class="breadcrumb-wrapper">
        <v-btn
            v-if="!isAtRoot"
            icon
            class="clear-button"
            aria-label="remove catalog"
            @click="deleteBreadcrumb()"
        >
            <v-icon>mdi-close-circle</v-icon>
        </v-btn>
        <v-breadcrumbs
            v-bind="$attrs"
            :items="filteredItems"
            class="breadcrumbs"
            v-on="$listeners"
        >
            <template v-slot:item="{ item }">
                <v-autocomplete
                    :key="item.id"
                    v-if="!loading && shouldDisplayDropdown( item ) && list.length"
                    v-model="breadcrumbValue[item.id]"
                    class="autocomplete-crumb"
                    :items="list"
                    :loading="loading"
                    :placeholder="item.placeholder"
                    :aria-label="item.placeholder"
                    :itemText="( value ) => getDisplayedValue( item, value )"
                    dense
                    outlined
                    hideDetails
                    returnObject
                    backgroundColor="white"
                    @input="(value) => selected(value, item)"
                />
                <!-- href allows for tabbing -->
                <v-breadcrumbs-item
                    v-else-if="shouldDisplayDropdown( item ) && !list.length && !loading && itemIndex( item ) > 0"
                    class="read-only-crumb"
                    href="#"
                >
                    {{ `No ${item.placeholder} to filter` }}
                </v-breadcrumbs-item>
                <v-breadcrumbs-item
                    v-else-if="itemIndex(item) < (filteredItems.length - 1)
                            || itemIndex(item) === items.length - 1"
                    class="textual-crumb"
                    href="#"
                    @click.stop.prevent="clickedBreadcrumb(item)"
                >
                    {{ breadcrumbText( item ) }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
    </div>
</template>

<script>
/**
 * Structure of items prop:
 * [
 *     {
 *         placeholder: 'string',
 *         displayedKey: 'string',
 *         actionName: 'Store action name',
 *         list: [objects with displayedKey prop, or anything if displayedKey is an empty string],
 *         childOf: [ids],
 *         id: 'string' - only needs to be unique among all the item ids,
 *     },
 * ],
 */
export default {
    name: 'BreadcrumbBase',
    props: {
        items: {
            type: Array,
            required: true,
            // Every item should contain placeholder, displayedKey, actionName, list, childOf, id
            validator: ( items ) => items.every( ( item ) => Object.keys( item ) !== [ 'placeholder, displayedKey, actionName, list, childOf, id' ] ),
        },
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            list: [],
            loading: false,
            initialized: false,
        };
    },
    computed: {
        breadcrumbValue: {
            get() {
                return { ...this.value };
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
        filteredItems() {
            // show if every item in childOf is defined in the breadcrumb value
            return this.items.filter(
                ( item ) => item.childOf.every( ( need ) => Object.keys( this.breadcrumbValue ).includes( need ) ),
            );
        },
        rootItems() {
            // show every item that has an empty childOf array
            return this.items.filter(
                ( item ) => item.childOf.length === 0,
            );
        },
        isAtRoot() {
            return Object.keys( this.breadcrumbValue ).length === 0;
        },
    },
    watch: {
        async items() {
            if ( this.initialized ) {
                await this.getList( this.filteredItems[ this.filteredItems.length - 1 ] );
            }
        },
    },
    async mounted() {
        this.initialized = true;
        if ( this.filteredItems?.length ) {
            const item = this.filteredItems[ this.filteredItems.length - 1 ];
            await this.getList( item );
        }
    },
    methods: {
        breadcrumbText( item ) {
            return this.breadcrumbValue[ item.id ] ? this.getDisplayedValue( item, this.breadcrumbValue[ item.id ] ) : '';
        },
        shouldDisplayDropdown( item ) {
            return !(
                this.breadcrumbValue[ item.id ]
                && this.getDisplayedValue( item, this.breadcrumbValue[ item.id ] )
            );
        },
        clickedBreadcrumb( { id } ) {
            // Let the parent know we're clearing stuff out, and then clear stuff out
            this.$emit( 'click:breadcrumb', { id } );

            const selectedIndex = this.items.findIndex( ( item ) => item.id === id );
            const newBreadcrumbValue = {};
            this.items.forEach( ( item, index ) => {
                if ( index < selectedIndex ) {
                    newBreadcrumbValue[ item.id ] = this.breadcrumbValue[ item.id ];
                }
            } );
            this.breadcrumbValue = newBreadcrumbValue;
            this.getList( this.items[ selectedIndex ] );
        },
        deleteBreadcrumb() {
            // Reset breadcrumbValue and re-initialize our list, then notify parent
            this.breadcrumbValue = {};
            this.getList( this.rootItems[ 0 ] );
            this.$emit( 'deleted' );
        },
        async getList( item ) {
            this.loading = true;
            this.list = [];
            try {
                if ( item.list && item.list.length ) {
                    this.list = item.list;
                } else if ( item.actionName ) {
                    const searchParameters = this.getSearchParameters( item );
                    this.list = await this.$store.dispatch( item.actionName, searchParameters );
                }
            } finally {
                this.loading = false;
                this.$emit( 'getList' );
            }
        },
        async getNextList( item ) {
            if ( this.itemIndex( item ) + 1 < this.items.length ) {
                await this.getList( this.items[ this.itemIndex( item ) + 1 ] );
            }
        },
        getSearchParameters( item ) {
            const ids = [ ...item.childOf ];
            const breadcrumbs = this.items.filter( ( breadcrumb ) => ids.includes( breadcrumb.id ) );
            const searchParameters = {};
            breadcrumbs.forEach( ( breadcrumb ) => {
                searchParameters[ breadcrumb.id ] = this.breadcrumbValue[ breadcrumb.id ];
            } );
            return searchParameters;
        },
        itemIndex( itemToFind ) {
            return this.items.findIndex( ( item ) => item.id === itemToFind.id );
        },
        selected( value, item ) {
            // Let the parent know which item was selected and get the next list
            this.$emit( 'selected', { breadcrumb: this.breadcrumbValue, id: item.id, value } );
            this.getNextList( item );
        },
        getDisplayedValue( item, value ) {
            if ( typeof item.displayedKey !== 'string' ) {
                return item.displayedKey( value );
            }
            return item.displayedKey.length > 0
                ? value[ item.displayedKey ]
                : value;
        },
    },
};
</script>

<style lang="scss" scoped>
    .breadcrumb-wrapper {
        display: flex;
        background-color: white;
        border-radius: .25rem;
        align-items: center;
        padding-left: .5rem;
        padding-right: .5rem;
        margin-top: .5rem;
    }

    .breadcrumbs {
        padding-left: .75rem;
        width: 100%;

        .autocomplete-crumb {
            &:not(:first-of-type) {
                padding-top: 8px;
            }
        }
        ::v-deep .v-breadcrumbs__divider {
            color: var(--v-primary-base);
            padding: 0 .25rem;
        }
        .textual-crumb {
            color: var(--v-primary-base);
            cursor: pointer;
            padding: 0;
            &:hover {
                text-decoration: underline;
            }
        }
        .read-only-crumb {
            color: var(--v-primary-base);
            padding: 0;
        }
    }
</style>
