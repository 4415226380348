<template>
    <div
        class="pagination"
        :class="rootClasses"
    >
        <ul
            v-if="!simple"
            class="pagination-list"
        >
            <!--First-->
            <li v-if="hasFirst">
                <button
                    role="button"
                    class="button pagination-link"
                    @click.prevent="first()"
                >
                    1
                </button>
            </li>
            <li v-if="hasFirstEllipsis"><span class="pagination-ellipsis">&hellip;</span></li>

            <!--Pages-->
            <li
                v-for="page in pagesInRange"
                :key="page.number"
            >
                <button
                    role="button"
                    class="button pagination-link"
                    :class="{ 'is-current': page.isCurrent }"
                    @click.prevent="page.click">
                    {{ page.number }}
                </button>
            </li>

            <!--Last-->
            <li v-if="hasLastEllipsis"><span class="pagination-ellipsis">&hellip;</span></li>
            <li v-if="hasLast">
                <button
                    role="button"
                    class="button pagination-link"
                    @click.prevent="last()"
                >
                    {{ pageCount }}
                </button>
            </li>
        </ul>

        <button
            role="button"
            class="button pagination-previous"
            :disabled="!hasPrev"
            @click.prevent="prev()"
        >
            <MaterialIcon
                iconName="chevron_left"
                class="icon"
            />
        </button>
        <button
            role="button"
            class="button pagination-next"
            :disabled="!hasNext"
            @click.prevent="next()"
        >
            <MaterialIcon
                iconName="chevron_right"
                class="icon"
            />
        </button>

        <small
            v-if="simple"
            class="info"
        >
            <template v-if="perPage == 1">
                {{ firstItem }} / {{ total }}
            </template>
            <template v-else>
                {{ firstItem }}-{{ Math.min( current * perPage, total ) }} / {{ total }}
            </template>
        </small>
    </div>
</template>

<script>
// Components
import MaterialIcon from '@/components/base/MaterialIcon';

export default {
    name: 'BPagination',
    components: {
        MaterialIcon,
    },
    props: {
        total: [ Number, String ],
        perPage: {
            type: [ Number, String ],
            required: false,
            default: 20,
        },
        current: {
            type: [ Number, String ],
            required: false,
            default: 1,
        },
        size: String,
        simple: {
            type: Boolean,
            required: false,
            default: false,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false,
        },
        order: String,
    },
    computed: {
        rootClasses() {
            return [
                this.order,
                this.size,
                {
                    'is-simple': this.simple,
                    'is-rounded': this.rounded,
                },
            ];
        },

        /**
         * Total page size (count).
         */
        pageCount() {
            return Math.ceil( this.total / this.perPage );
        },

        /**
         * First item of the page (count).
         */
        firstItem() {
            // eslint-disable-next-line no-mixed-operators
            const firstItem = this.current * this.perPage - this.perPage + 1;
            return firstItem >= 0 ? firstItem : 0;
        },

        /**
         * Check if previous button is available.
         */
        hasPrev() {
            return this.current > 1;
        },

        /**
         * Check if first page button should be visible.
         */
        hasFirst() {
            return this.current >= 3;
        },

        /**
         * Check if first ellipsis should be visible.
         */
        hasFirstEllipsis() {
            return this.current >= 4;
        },

        /**
         * Check if last page button should be visible.
         */
        hasLast() {
            return this.current <= this.pageCount - 2;
        },

        /**
         * Check if last ellipsis should be visible.
         */
        hasLastEllipsis() {
            return this.current < this.pageCount - 2 && this.current <= this.pageCount - 3;
        },

        /**
         * Check if next button is available.
         */
        hasNext() {
            return this.current < this.pageCount;
        },

        /**
         * Get near pages, 1 before and 1 after the current.
         * Also add the click event to the array.
         */
        pagesInRange() {
            if ( this.simple ) return;

            const left = Math.max( 1, this.current - 1 );
            const right = Math.min( this.current + 1, this.pageCount );

            const pages = [];
            // eslint-disable-next-line no-plusplus
            for ( let i = left; i <= right; i++ ) {
                pages.push( {
                    number: i,
                    isCurrent: this.current === i,
                    click: ( event ) => {
                        if ( this.current === i ) return;
                        this.$emit( 'change', i );
                        this.$emit( 'update:current', i );

                        // Set focus on element to keep tab order
                        this.$nextTick( () => event.target.focus() );
                    },
                } );
            }
            // eslint-disable-next-line consistent-return
            return pages;
        },
    },
    watch: {
        /**
         * If current page is trying to be greater than page count, set to last.
         */
        pageCount( value ) {
            if ( this.current > value ) this.last();
        },
    },
    methods: {
        /**
         * Previous button click listener.
         */
        prev() {
            if ( !this.hasPrev ) return;
            this.$emit( 'change', this.current - 1 );
            this.$emit( 'update:current', this.current - 1 );
        },

        /**
         * First button click listener.
         */
        first() {
            this.$emit( 'change', 1 );
            this.$emit( 'update:current', 1 );
        },

        /**
         * Last button click listener.
         */
        last() {
            this.$emit( 'change', this.pageCount );
            this.$emit( 'update:current', this.pageCount );
        },

        /**
         * Next button click listener.
         */
        next() {
            if ( !this.hasNext ) return;
            this.$emit( 'change', this.current + 1 );
            this.$emit( 'update:current', this.current + 1 );
        },
    },
};
</script>

<style scoped lang="scss">
.button {
    background-color: rgba(0, 0, 0, 0);
}
ul {
     list-style: none;
 }

.icon {
    font-size: 24px;
}

[disabled].button,
[disabled].input,
.taginput [disabled].taginput-container.is-focusable,
[disabled].textarea,
.select select[disabled],
[disabled].file-cta,
[disabled].file-name,
[disabled].pagination-previous,
[disabled].pagination-next,
[disabled].pagination-link,
[disabled].pagination-ellipsis {
    cursor: not-allowed;
    *{
        cursor: not-allowed;
    }
}

.pagination {
    font-size: 1rem;
    margin: -0.25rem;
}

.pagination {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.pagination,
.pagination-list {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.pagination-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.pagination .is-current {
     pointer-events: none;
     cursor: not-allowed;
 }

.pagination-link.is-current {
    background-color: #3273dc;
    border-color: #3273dc;
    color: #fff;
}

.pagination-previous[disabled],
.pagination-next[disabled],
.pagination-link[disabled] {
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #7a7a7a;
    opacity: 0.5;
}

.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
    font-size: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0.25rem;
    text-align: center;
}

.pagination-previous,
.pagination-next,
.pagination-link {
    border-color: #dbdbdb;
    color: #363636;
    min-width: 2.25em;
}

.pagination-previous,
.pagination-next {
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
}

.pagination .pagination-next,
.pagination .pagination-previous {
    padding-left: 0.25em;
    padding-right: 0.25em;
}

.pagination {
    button {
        margin: 0.25rem;
    }
}

.pagination-ellipsis {
    color: #4a4a4a;
}
</style>
