<template>
    <div id="catalogListingStatus">
        <label for="catalogListingSelectionDropdown"> Status: </label>
        <v-select
            id="catalogListingSelectionDropdown"
            v-model="listingStatus"
            :items="catalogListingStatuses"
            dense
            hideDetails
            class="catalog-listing-status-dropdown"
        />
    </div>
</template>

<script>
// Vuex
import { mapActions, mapState } from 'vuex';

export default {
    name: 'CurrentListingStatusSelection',
    computed: {
        ...mapState( {
            catalogListingStatuses: ( state ) => state.GlobalResources.catalogListingStatusList,
            currentCatalogListing: ( state ) => state.currentCatalogListing,
        } ),

        listingStatus: {
            get() {
                return this.currentCatalogListing.status;
            },
            set( status ) {
                this.updateCurrentCatalogListing( {
                    key: 'status',
                    value: status,
                } );
            },
        },
    },
    methods: {
        ...mapActions( {
            updateCurrentCatalogListing: 'updateCurrentCatalogListing',
        } ),
    },
};
</script>

<style lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/misc.scss";

    #catalogListingStatus {
        margin-left: 15px;
        letter-spacing: 1px;
        display: flex;
        align-items: center;

        label {
            margin-right: 5px;
        }

        .v-select.catalog-listing-status-dropdown {
            width: 11rem;
            .v-select {
                &__selection {
                    padding-left: 0;
                }
            }
        }
    }

    #catalogListingSelectionDropdown {
        width: 0;
        padding: 0;
        margin: 0;
        .v-text-field.v-input--dense & {
            padding-left: 0 !important;
        }
    }

    #catalogListingSelectionDropdown:focus {
        outline: none;
    }
</style>
