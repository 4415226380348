<template>
    <ol id="itemList">
        <li v-if="isListEmpty" class="item">None Selected</li>
        <li
            v-for="item in itemList"
            :key="item.id"
            class="item"
        >
            {{ item[ displayedKey ] }}
        </li>
    </ol>
</template>

<script>
export default {
    name: 'WordcloudBase',
    props: {
        itemList: {
            type: Array, // of objects, must have 'id' and [displayedKey]
            required: false,
            default: () => [],
        },
        displayedKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        isListEmpty() {
            return this.itemList.length === 0;
        },
    },
};
</script>

<style scoped>
    ol,ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    #itemList {
        width: 100%;
        max-width: 800px;
        max-height: 13rem;
        /*display: flex;*/
        /*flex-flow: row wrap;*/
    }
    .item{
        font-size: .9rem;
        padding: 5px 1rem;
        color: #4a4949;
        margin: 0;
        /*flex-basis: 33.33%;*/
    }
</style>
