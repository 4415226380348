<template>
    <div v-if="hasCurrentListingAndCurrentTitle" class="catalog-listing-detail-container">
        <ModifyTitle class="box-shadow edit-title" v-bind="currentTitle" @changes="updateTitleModifications" @removal="removeTitleModifications" />

        <DigitalPrices id="digital-pricing" :productCode="currentTitle.productCode" class="box-shadow" />

        <SlingshotLibraries
            :selected-libraries="slingshotLibraries"
            :titleIsbn="currentTitle.productCode"
            class="slingshot-libraries multi-option-container box-shadow"
        />
        <VendorLibraries
            :selected-libraries="vendorLibraries"
            :titleIsbn="currentTitle.productCode"
            class="vendor-libraries multi-option-container box-shadow"
        />

        <CatalogListingDesignators
            :preSelectedList="currentCatalogListingDesignators"
            class="overrides multi-option-container box-shadow"
            @changes="updateCurrentCatalogListing"
        />

        <!-- Listing Notes -->
        <div class="listing-notes listing-detail-info-container box-shadow">
            <div class="listing-detail-info-content">
                <h2>Listing Notes</h2>
                <v-textarea v-model="listingNotes" outlined rows="5" placeholder="Notes for this listing"></v-textarea>
            </div>
        </div>

        <!-- External Listing Notes -->
        <div class="external-listing-notes listing-detail-info-container box-shadow">
            <div class="listing-detail-info-content">
                <h2>External Listing Notes</h2>
                <p>{{ currentCatalogListing.externalListingNote }}</p>
            </div>
        </div>

        <ContextualInfoGroup class="catalog-information" />

        <!-- Inclusive Access Assign Pricing record -->
        <InclusiveAccessPrices
            id="ia-pricing"
            :iaActive="currentCatalogListing.ia"
            :title="currentTitle"
            selectablePrices
            @changes="setCurrentCatalogListingPricingInfo"
            @removal="setCurrentCatalogListingPricingInfo"
        />
    </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex';

// Components
import DigitalPrices from '@/views/common/DigitalPrices';
import InclusiveAccessPrices from '@/views/common/InclusiveAccessPrices';
import ModifyTitle from '@/views/common/ModifyTitle';
import CatalogListingDesignators from '@/views/common/CatalogListingDesignators';
import SlingshotLibraries from '@/components/multioption/SlingshotLibraries';
import VendorLibraries from '@/components/multioption/VendorLibraries';
import ContextualInfoGroup from './listingDetail/ContextualInfoGroup';

export default {
    name: 'ListingDetail',
    components: {
        VendorLibraries,
        SlingshotLibraries,
        ContextualInfoGroup,
        DigitalPrices,
        InclusiveAccessPrices,
        ModifyTitle,
        CatalogListingDesignators,
    },
    computed: {
        ...mapGetters({
            hasCatalogListings: 'CatalogListing/hasListings',
            hasCurrentTitle: 'hasCurrentTitle',
            currentCatalogListingDesignators: 'currentCatalogListingDesignators',
        }),
        ...mapState({
            currentCatalogListing: (state) => state.currentCatalogListing,
            currentTitle: (state) => state.currentTitle,
            selectedLibraries: (state) => state.Title.LibraryManagement.selectedLibraries,
        }),

        slingshotLibraries() {
            return this.selectedLibraries.filter(({ manuallyManaged }) => manuallyManaged);
        },
        vendorLibraries() {
            return this.selectedLibraries.filter(({ manuallyManaged }) => !manuallyManaged);
        },

        /** @returns { Boolean } */
        hasCurrentListingAndCurrentTitle() {
            return this.hasCatalogListings && this.hasCurrentTitle;
        },
        listingNotes: {
            get() {
                return this.currentCatalogListing.note;
            },
            set(note) {
                this.updateCurrentCatalogListing({
                    key: 'note',
                    value: note,
                });
            },
        },
    },
    beforeMount() {
        this.initializeGlobalResources();
        this.fetchSelectedLibraries(this.currentTitle.productCode);
    },
    methods: {
        ...mapActions({
            initializeGlobalResources: 'GlobalResources/init',
            initializeTitleModifications: 'CatalogListing/Detail/initializeTitleModifications',
            updateTitleModifications: 'CatalogListing/Detail/updateTitleModifications',
            removeTitleModifications: 'CatalogListing/Detail/removeTitleModifications',
            updateCurrentCatalogListing: 'updateCurrentCatalogListing',
            fetchSelectedLibraries: 'Title/LibraryManagement/fetchSelectedLibraries',
        }),

        /**
         * @param { Object } attributes
         * @property { catalogListing.catalogPricing }  attributes.catalogPricing
         * @property { catalogListing.ia }              attributes.ia
         * @property { catalogListing.fulfillmentType } attributes.fulfillmentType
         */
        setCurrentCatalogListingPricingInfo(attributes) {
            Object.keys(attributes).forEach((key) => {
                this.updateCurrentCatalogListing({
                    key,
                    value: attributes[key],
                });
            });
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/listingdetails.scss";

/* Do NOT scope this style tag */
/* Several child components rely on these styles */
/* Name styles very specifically, and we won't have a problem */

.listing-detail-info-container {
    color: $list-container-fg;
    text-align: left;
    border-radius: 2px;
    padding: 1rem;
}

.cancel-button {
    grid-column-start: 6;
    cursor: pointer;
}

h2,
h3,
h4,
h5 {
    margin-top: 0;
}

h2 {
    font-variant-caps: all-small-caps;
}

h3 {
    font-weight: normal;
    font-size: 0.8rem;
}

.multi-option-container {
    padding: 1rem;
}

.catalog-listing-detail-container {
    display: grid;
    grid-template:
        "title digital-pricing"
        "title slingshot-libraries"
        "title vendor-libraries"
        "overrides overrides"
        "listing-notes external"
        "ia-pricing ia-pricing"
        "context context"
        /1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 1%;
    margin-bottom: 8rem;
}

.catalog-listing-detail-container {
    .edit-title {
        grid-area: title;
        padding: 1rem;

        &__designators {
            margin-top: 1rem;
        }

        &__notes {
            margin-top: 1rem;
        }
    }

    .external-listing-notes {
        grid-area: external;
    }

    .listing-notes {
        grid-area: listing-notes;
    }

    .catalog-information {
        grid-area: context;
    }
}

#ia-pricing {
    grid-area: ia-pricing;
}
</style>
