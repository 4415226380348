<template>
    <ModalBase
        class="reassign-modal stylized"
        :containerStyleOverride="containerStyle"
        @close="closeModal()"
    >
        <div slot="header">
            <h2>Reassign Titles</h2>
        </div>
        <!-- Override default body -->
        <div slot="body">
            <div class="body-content">
                <span class="column-title-text-detail">
                    Reassign <span class="bold-text"> {{ selectedTitles.length }} </span> title{{selectedTitles.length > 1 ? 's' : ' '}} to
                </span>

                <DropdownSelectAssignee
                    v-model="assignee"
                    outlined
                />
            </div>
        </div>
        <div slot="footer">
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    ref="buttonAssign"
                    color="primary"
                    :disabled="!assignee || loading"
                    :loading="loading"
                    @click="reassignSelectedTitles()"
                    @keyup.enter="reassignSelectedTitles()"
                >
                    Assign
                </v-btn>
            </v-card-actions>
        </div>
    </ModalBase>
</template>

<script>
// Components
import ModalBase from '@/components/base/modals/ModalBase';
import DropdownSelectAssignee from '@/components/dropdowns/DropdownSelectAssignee';

/**
 * @emits 'close'
 */
export default {
    name: 'ModalReassignSelectedTitles',
    components: {
        ModalBase,
        DropdownSelectAssignee,
    },
    props: {
        selectedTitles: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            containerStyle: {
                width: '440px',
            },
            assignee: {},
            loading: false,
        };
    },
    watch: {
        assignee() {
            if ( this.assignee?.username ) {
                this.$nextTick( () => this.$refs.buttonAssign.$el.focus() );
            }
        },
    },
    methods: {
        closeModal() {
            this.$emit( 'close' );
        },

        async refreshTitlesToAssignAndResearch() {
            try {
                // Refresh the Title/Assignment/list and Title/Queue/list after we've assigned titles
                await this.$store.dispatch( 'Title/Assignment/getList' );
                await this.$store.dispatch( 'Title/Queue/getList' );
            } catch ( error ) {
                console.log( 'Error in refreshTitlesToAssignAndResearch: ', error );
            }
        },

        async reassignSelectedTitles() {
            this.loading = true;
            try {
                await this.$store.dispatch( 'Title/Assignment/assignTitleList', {
                    titleList: this.selectedTitles,
                    assignee: this.assignee?.username,
                } );
                await this.refreshTitlesToAssignAndResearch();
                this.$emit( 'reassigned' );
                this.closeModal();
            } catch ( error ) {
                console.log( 'Error in reassignSelectedTitles: ', error );
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.reassign-modal {
    .body-content {
        text-align: left;
        margin: 1rem;
    }

    .bold-text {
        font-weight: bold;
        color: black;
    }

    .column-title-text-detail {
        color: #656565;
        font-size: 14px;
    }
}
</style>
