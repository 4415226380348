<template>
    <div
        class="table-base"
        :class="{ 'is-loading': loading }"
    >

        <div class="table-wrapper">
            <table
                class="table"
                :class="tableClasses"
                :tabindex="!focusable ? false : 0"
            >
                <!-- Table Head -->
                <thead v-if="newColumns.length">
                    <tr>

                        <!-- Custom Prefix Heading Slot -->
                        <th v-if="hasCustomPrefixColumn">
                            <slot name="customPrefixHeading"></slot>
                        </th>

                        <!-- Detail Spacing -->
                        <th
                            v-if="detailed"
                            width="40px"
                        />

                        <!-- Check All Checkbox -->
                        <th
                            v-if="checkable"
                            class="checkbox-cell"
                        >
                            <input
                                type="checkbox"
                                aria-label="check-all"
                                :checked="isAllChecked"
                                :disabled="isAllUncheckable"
                                @change="checkAll()"
                            >
                        </th>

                        <!-- Data Column Headings -->
                        <th
                            v-for="( column, index ) in visibleColumns"
                            :key="index"
                            :class="{
                                'is-current-sort': currentSortColumn === column,
                                'is-sortable': column.sortable
                            }"
                            :style="{ width: column.width + 'px' }"
                            @click.stop="sort( column )"
                        >
                            <div>
                                <!-- Scoped Slot - Header ( Placeholder ) -->

                                {{ column.label }}
                                <MaterialIcon
                                    v-show="currentSortColumn === column"
                                    iconName="arrow_upward"
                                    class="icon"
                                    :class="{ 'is-desc': !isAsc }"
                                />
                            </div>
                        </th>
                    </tr>
                </thead>

                <!-- Table Body -->
                <tbody v-if="visibleData.length">
                    <template v-for="( row, index ) in visibleData">
                        <!-- Rows -->
                        <tr
                            :key="index"
                            :class="[ rowClass( row, index ), {
                                'is-selected': row === selected,
                                'is-checked': isRowChecked( row )
                            } ]"
                            @click="selectRow( row )"
                        >

                            <!-- Custom Prefix Content Slot-->
                            <td
                                v-if="hasCustomPrefixColumn"
                                @click.stop
                            >
                                <slot
                                    name="customPrefixContent"
                                    :row="row"
                                    :index="index"
                                ></slot>
                            </td>

                            <!-- Detail Expand Icon -->
                            <td
                                v-if="detailed"
                                class="chevron-cell"
                            >
                                <a
                                    v-if="hasDetailedVisible( row )"
                                    role="button"
                                    @click.stop="toggleDetails( row )"
                                >
                                    <!-- rotate by 90 degrees when expanded -->
                                    <MaterialIcon
                                        iconName="chevron_right"
                                        class="icon"
                                        :class="{'is-expanded': isVisibleDetailRow(row)}"
                                    />
                                </a>
                            </td>

                            <!-- Row Checkbox -->
                            <td
                                v-if="checkable"
                                class="checkbox-cell"
                                @click.stop
                            >
                                <input
                                    type="checkbox"
                                    :checked="isRowChecked( row )"
                                    :disabled="!isRowCheckable( row )"
                                    @change="checkRow( row )"
                                >
                            </td>

                            <!-- Scoped Slot - Default ( Placeholder ) -->

                            <!-- Row Data -->
                            <td
                                v-for="column in newColumns"
                                :key="column.label"
                                :data-label="column.label"
                            >
                                {{ getValueByPath( row, column.field ) }}
                            </td>

                        </tr>

                        <!-- Expanded Detail Info -->
                        <tr
                            :key="( index + 100000 )"
                            v-if="detailed && isVisibleDetailRow( row )"
                            class="detail"
                        >
                            <td :colspan="columnCount">
                                <div class="detail-container">
                                    <slot
                                        name="detail"
                                        :row="row"
                                        :index="index"
                                    />
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="is-empty">
                        <td :colspan="columnCount">
                            <slot name="empty"/>
                        </td>
                    </tr>
                </tbody>

                <!-- Footer Slot -->
                <tfoot v-if="$slots.footer !== undefined">
                    <tr class="table-footer">
                        <slot
                            v-if="hasCustomFooterSlot()"
                            name="footer"
                        />
                        <th
                            v-else
                            :colspan="columnCount"
                        >
                            <slot name="footer"/>
                        </th>
                    </tr>
                </tfoot>

            </table>
        </div>

        <!-- Pagination -->
        <div
            v-if="( checkable && hasBottomLeftSlot() ) || paginated"
            class="level"
        >
            <div class="level-left">
                <slot name="bottom-left"/>
            </div>

            <div class="level-right">
                <div
                    v-if="paginated"
                    class="level-item"
                >
                    <PaginationBase
                        :total="newDataTotal"
                        :perPage="perPage"
                        :simple="paginationSimple"
                        :size="paginationSize"
                        :current="newCurrentPage"
                        @change="( number ) => pageChanged( number )"
                    />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
// Components
import MaterialIcon from '@/components/base/MaterialIcon';
import PaginationBase from './PaginationBase';

// eslint-disable-next-line no-unused-vars
function getValueByPath( obj, path ) {
    return path.split( '.' ).reduce( ( o, i ) => o[ i ], obj );
}
/**
 * Extension of indexOf method by equality function if specified
 */
export function indexOf( array, obj, fn ) {
    if ( !array ) return -1;

    if ( !fn || typeof fn !== 'function' ) return array.indexOf( obj );

    // eslint-disable-next-line no-plusplus
    for ( let i = 0; i < array.length; i++ ) {
        if ( fn( array[ i ], obj ) ) {
            return i;
        }
    }

    return -1;
}

export default {
    name: 'TableBase',
    components: {
        MaterialIcon,
        PaginationBase,
    },
    props: {
        data: {
            type: Array,
            default: () => ( [] ),
            required: true,
        },
        columns: {
            type: Array,
            default: () => ( [] ),
            required: true,
        },
        bordered: {
            type: Boolean,
            required: false,
            default: false,
        },
        narrowed: {
            type: Boolean,
            required: false,
            default: false,
        },
        hoverable: {
            type: Boolean,
            required: false,
            default: false,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        detailed: {
            type: Boolean,
            required: false,
            default: false,
        },
        checkable: {
            type: Boolean,
            required: false,
            default: false,
        },
        selected: {
            type: Object,
            default: () => ( {} ),
            required: false,
        },
        focusable: {
            type: Boolean,
            required: false,
            default: false,
        },
        customIsChecked: {
            type: Function,
            required: false,
            default: undefined,
        },
        isRowCheckable: {
            type: Function,
            required: false,
            default: () => true,
        },
        checkedRows: {
            type: Array,
            required: false,
            default: () => ( [] ),
        },
        // mobileCards
        defaultSort: {
            type: [ String, Array ],
            required: false,
            default: '',
        },
        defaultSortDirection: {
            type: String,
            required: false,
            default: 'asc',
        },
        paginated: {
            type: Boolean,
            required: false,
            default: false,
        },
        currentPage: {
            type: Number,
            required: false,
            default: 1,
        },
        perPage: {
            type: [ Number, String ],
            required: false,
            default: 20,
        },
        paginationSimple: {
            type: Boolean,
            required: false,
            default: false,
        },
        paginationSize: {
            type: String,
            required: false,
            default: '',
        },
        backendSorting: {
            type: Boolean,
            required: false,
            default: false,
        },
        rowClass: {
            type: Function,
            required: false,
            default: () => '',
        },
        openedDetailed: {
            type: Array,
            required: false,
            default: () => ( [] ),
        },
        hasDetailedVisible: {
            type: Function,
            required: false,
            default: () => true,
        },
        detailKey: {
            type: String,
            required: false,
            default: '',
        },
        backendPagination: {
            type: Boolean,
            required: false,
            default: false,
        },
        total: {
            type: [ Number, String ],
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            getValueByPath,
            newColumns: [ ...this.columns ],
            visibleDetailRows: this.openedDetailed,
            newData: this.data,
            newDataTotal: this.backendPagination ? this.total : this.data.length,
            newCheckedRows: [ ...this.checkedRows ],
            newCurrentPage: this.currentPage,
            currentSortColumn: {},
            isAsc: true,
            firstTimeSort: true, // Used by first time initSort
            // _isTable
        };
    },
    computed: {
        tableClasses() {
            return {
                'is-bordered': this.bordered,
                'is-narrow': this.narrowed,
                // 'has-mobile-cards': this.mobileCards,
                'is-hoverable': (
                    ( this.hoverable || this.focusable )
                    && this.visibleData.length
                ),
            };
        },

        visibleColumns() {
            return this.newColumns.filter( ( column ) => column.visible || column.visible === undefined );
        },

        /**
         * Splitted data based on the pagination.
         */
        visibleData() {
            if ( !this.paginated ) return this.newData;

            const currentPage = this.newCurrentPage;
            const { perPage } = this;

            if ( this.newData.length <= perPage ) {
                return this.newData;
            }
            const start = ( currentPage - 1 ) * perPage;
            const end = parseInt( start, 10 ) + parseInt( perPage, 10 );
            return this.newData.slice( start, end );
        },

        /**
         * Check if all rows in the page are checked.
         */
        isAllChecked() {
            const validVisibleData = this.visibleData.filter( ( row ) => this.isRowCheckable( row ) );
            if ( validVisibleData.length === 0 ) return false;
            const isAllChecked = validVisibleData.some( ( currentVisibleRow ) => indexOf( this.newCheckedRows, currentVisibleRow, this.customIsChecked ) < 0 );
            return !isAllChecked;
        },

        /**
         * Check if all rows in the page are checkable.
         */
        isAllUncheckable() {
            return this.visibleData
                .filter( ( row ) => this.isRowCheckable( row ) )
                .length === 0;
        },

        /**
         * Check if has any sortable column.
         */
        hasSortablenewColumns() {
            return this.newColumns.some( ( column ) => column.sortable );
        },

        /**
         * Return total column count based if it's checkable or expanded
         */
        columnCount() {
            let count = this.newColumns.length;
            count += this.checkable ? 1 : 0;
            count += this.detailed ? 1 : 0;
            return count;
        },
    },
    watch: {
        /**
         * When data prop change:
         *   1. Update internal value.
         *   2. Reset newColumns (thead), in case it's on a v-for loop.
         *   3. Sort again if it's not backend-sort.
         *   4. Set new total if it's not backend-paginated.
         */
        data( value ) {
            // Save newColumns before resetting
            const { newColumns } = this;

            this.newColumns = [];
            this.newData = value;

            // Prevent table from being headless, data could change and created hook
            // on column might not trigger
            this.$nextTick( () => {
                if ( !this.newColumns.length ) this.newColumns = newColumns;
            } );

            if ( !this.backendSorting ) {
                this.sort( this.currentSortColumn, true );
            }
            if ( !this.backendPagination ) {
                this.newDataTotal = value.length;
            }
        },

        /**
         * When Pagination total change, update internal total
         * only if it's backend-paginated.
         */
        total( newTotal ) {
            if ( !this.backendPagination ) return;
            this.newDataTotal = newTotal;
        },

        /**
         * When checkedRows prop change, update internal value without
         * mutating original data.
         */
        checkedRows( rows ) {
            this.newCheckedRows = [ ...rows ];
        },

        columns( value ) {
            this.newColumns = [ ...value ];
        },

        /**
         * When newColumns change, call initSort only first time (For example async data).
         */
        newColumns( newColumns ) {
            if ( newColumns.length && this.firstTimeSort ) {
                this.initSort();
                this.firstTimeSort = false;
            } else if ( newColumns.length ) {
                if ( this.currentSortColumn.field ) {
                    // eslint-disable-next-line no-plusplus
                    for ( let i = 0; i < newColumns.length; i++ ) {
                        if ( newColumns[ i ].field === this.currentSortColumn.field ) {
                            this.currentSortColumn = newColumns[ i ];
                            break;
                        }
                    }
                }
            }
        },

        /**
         * When the user wants to control the detailed rows via props.
         * Or wants to open the details of certain row with the router for example.
         */
        openedDetailed( expandedRows ) {
            this.visibleDetailRows = expandedRows;
        },

        // currentPage
    },
    mounted() {
        this.checkPredefinedDetailedRows();

        // initSort does NOT get called here originally in Buefy
        this.initSort();

        // This if statement was implemented to use this table on the CatalogListingSearch page
        // We already have data, we're not going to the backend to refresh it so newColumns won't run,
        // so we need a way to turn OFF this.firstTimeSort
        if ( this.data && this.data.length && this.data.length > 1 && this.currentSortColumn ) {
            this.firstTimeSort = false;
        }
    },
    methods: {
        /**
         * Sort an array by key without mutating original data.
         * Call the user sort function if it was passed.
         */
        sortBy( array, key, fn, isAsc ) {
            let sorted = [];
            // Sorting without mutating original data
            if ( fn && typeof fn === 'function' ) {
                sorted = [ ...array ].sort( ( a, b ) => fn( a, b, isAsc ) );
            } else {
                sorted = [ ...array ].sort( ( a, b ) => {
                    // Get nested values from objects
                    let newA = getValueByPath( a, key );
                    let newB = getValueByPath( b, key );

                    if ( !newA && newA !== 0 ) return 1;
                    if ( !newB && newB !== 0 ) return -1;
                    if ( newA === newB ) return 0;

                    newA = ( typeof newA === 'string' )
                        ? newA.toUpperCase()
                        : newA;
                    newB = ( typeof newB === 'string' )
                        ? newB.toUpperCase()
                        : newB;

                    // eslint-disable-next-line no-nested-ternary
                    return isAsc
                        ? newA > newB ? 1 : -1
                        : newA > newB ? -1 : 1;
                } );
            }

            return sorted;
        },

        /**
         * Sort the column.
         * Toggle current direction on column if it's sortable
         * and not just updating the prop.
         */
        sort( column, updatingData = false ) {
            if ( !column || !column.sortable ) return;

            if ( !updatingData ) {
                this.isAsc = column === this.currentSortColumn
                    ? !this.isAsc
                    : ( this.defaultSortDirection.toLowerCase() !== 'desc' );
            }
            if ( !this.firstTimeSort ) {
                this.$emit( 'sort', column.field, this.isAsc ? 'asc' : 'desc' );
            }
            if ( !this.backendSorting ) {
                this.newData = this.sortBy(
                    this.newData,
                    column.field,
                    column.customSort,
                    this.isAsc,
                );
            }
            this.currentSortColumn = column;
        },

        /**
         * Check if the row is checked (is added to the array).
         */
        isRowChecked( row ) {
            return indexOf( this.newCheckedRows, row, this.customIsChecked ) >= 0;
        },

        /**
         * Remove a checked row from the array.
         */
        removeCheckedRow( row ) {
            const index = indexOf( this.newCheckedRows, row, this.customIsChecked );
            if ( index >= 0 ) {
                this.newCheckedRows.splice( index, 1 );
            }
        },

        /**
         * Header checkbox click listener.
         * Add or remove all rows in current page.
         */
        checkAll() {
            const { isAllChecked } = this;
            this.visibleData.forEach( ( currentRow ) => {
                this.removeCheckedRow( currentRow );
                if ( !isAllChecked ) {
                    if ( this.isRowCheckable( currentRow ) ) {
                        this.newCheckedRows.push( currentRow );
                    }
                }
            } );

            this.$emit( 'check', this.newCheckedRows );
            this.$emit( 'check-all', this.newCheckedRows );

            // Emit checked rows to update user variable
            this.$emit( 'update:checkedRows', this.newCheckedRows );
        },

        /**
         * Row checkbox click listener.
         * Add or remove a single row.
         */
        checkRow( row ) {
            if ( !this.isRowChecked( row ) ) {
                this.newCheckedRows.push( row );
            } else {
                this.removeCheckedRow( row );
            }

            this.$emit( 'check', this.newCheckedRows, row );

            // Emit checked rows to update user variable
            this.$emit( 'update:checkedRows', this.newCheckedRows );
        },

        /**
         * Row click listener.
         * Emit all necessary events.
         */
        // eslint-disable-next-line no-unused-vars
        selectRow( row, index ) {
            this.$emit( 'click', row );

            if ( this.selected === row ) return;

            // Emit new and old row
            this.$emit( 'select', row, this.selected );

            // Emit new row to update user variable
            this.$emit( 'update:selected', row );
        },

        /**
         * Paginator change listener.
         */
        pageChanged( page ) {
            this.newCurrentPage = page > 0
                ? page
                : 1;
            this.$emit( 'page-change', this.newCurrentPage );
            this.$emit( 'update:currentPage', this.newCurrentPage );
        },

        /**
         * Toggle to show/hide details slot
         */
        toggleDetails( obj ) {
            const found = this.isVisibleDetailRow( obj );

            if ( found ) {
                this.closeDetailRow( obj );
                this.$emit( 'details-close', obj );
            } else {
                this.openDetailRow( obj );
                this.$emit( 'details-open', obj );
            }

            // Syncs the detailed rows with the parent component
            this.$emit( 'update:openedDetailed', this.visibleDetailRows );
        },

        openDetailRow( obj ) {
            const index = this.handleDetailKey( obj );
            this.visibleDetailRows.push( index );
        },

        closeDetailRow( obj ) {
            const index = this.handleDetailKey( obj );
            const i = this.visibleDetailRows.indexOf( index );
            this.visibleDetailRows.splice( i, 1 );
        },

        isVisibleDetailRow( obj ) {
            const index = this.handleDetailKey( obj );
            return this.visibleDetailRows.indexOf( index ) >= 0;
        },

        /**
         * When the detailKey is defined we use the object[detailKey] as index.
         * If not, use the object reference by default.
         */
        handleDetailKey( index ) {
            const key = this.detailKey;
            return !key.length
                ? index
                : index[ key ];
        },

        checkPredefinedDetailedRows() {
            const defaultExpandedRowsDefined = this.openedDetailed.length > 0;
            if ( defaultExpandedRowsDefined && !this.detailKey.length ) {
                throw new Error( 'If you set a predefined opened-detailed, you must provide an unique key using the prop "detail-key"' );
            }
        },

        /**
         * Check if footer slot has custom content.
         */
        hasCustomFooterSlot() {
            if ( this.$slots.footer.length > 1 ) return true;

            const { tag } = this.$slots.footer[ 0 ];
            return !( tag !== 'th' && tag !== 'td' );
        },

        /**
         * Check if bottom-left slot exists.
         */
        hasBottomLeftSlot() {
            return typeof this.$slots[ 'bottom-left' ] !== 'undefined';
        },

        hasCustomPrefixColumn() {
            return !!this.$slots.customPrefixHeading
                || !!this.$slots.customPrefixContent;
        },

        // pressedArrow

        /**
         * Focus table element if has selected prop.
         */
        focus() {
            if ( !this.focusable ) return;

            this.$el.querySelector( 'table' ).focus();
        },

        /**
         * Initial sorted column based on the default-sort prop.
         */
        initSort() {
            if ( !this.defaultSort ) return;

            let sortField = '';
            let sortDirection = this.defaultSortDirection;

            if ( Array.isArray( this.defaultSort ) ) {
                [ sortField ] = this.defaultSort;
                if ( this.defaultSort[ 1 ] ) {
                    [ , sortDirection ] = this.defaultSort;
                }
            } else {
                sortField = this.defaultSort;
            }

            this.newColumns.forEach( ( column ) => {
                if ( column.field === sortField ) {
                    this.isAsc = sortDirection.toLowerCase() !== 'desc';
                    this.sort( column, true );
                }
            } );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.table-base {
    font-size: .6875rem;
    transition: opacity 86ms ease-out;
    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
    }
    .table {
        border: 1px solid transparent;
        border-spacing: 0;
        width: 100%;
        tr{
            &:hover {
                background-color: $hover-gray;
            }
            &.last-viewed-item td:first-of-type {
                border-left: 5px solid $button-blue !important;
                margin-left: -5px !important;
            }
            &.is-searched-title td:first-of-type {
                border-left: 5px solid $add-green !important;
                margin-left: -5px !important;
            }
            &.detail {
                box-shadow: inset 0 1px 3px $light-gray;
                background: $very-light-gray;

                &:hover {
                    background-color: $hover-gray;
                }
            }
        }
        th {
            color: #363636;
            padding: 0.5em 0.75em;
            vertical-align: baseline;
            border-bottom: 2px solid $gray-border;
            white-space: nowrap;
            .is-sortable {
                cursor: pointer;
            }

            .is-current-sort {
                border-color: #7a7a7a;
                font-weight: 700;
            }
        }
        td {
            border-width: 0 0 1px;
            padding: 0.5em 0.75em;
            vertical-align: top;
            border-bottom: 1px solid $gray-border;
            .chevron-cell {
                vertical-align: middle;

                a .icon {
                    font-size: 24px;
                }
            }
            .level {
                padding-bottom: 1.5rem;
            }
           ::v-deep .material-icons {
                font-size: 1rem;
                padding: .0;
            }
        }
    }
    .icon {
        transition:
            transform 150ms ease-out,
            opacity 86ms ease-out,
            -webkit-transform 150ms ease-out;

        &.is-expanded {
            transform: rotate( 90deg );
        }

        &.is-desc {
            transform: rotate( 180deg );
        }
    }
    .is-empty td {
        border: none;
    }
}

/* Pagination */
.level {
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.level-item,
.level-left,
.level-right {
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
}
.level-left {
    justify-content: flex-start;
}
.level-item {
    justify-content: center;
}

@keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.is-loading::after {
    animation: spinAround 500ms infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
}
.table-base.is-loading::after {
    position: absolute;
    top: 4em;
    left: calc(50% - 2.5em);
    width: 5em;
    height: 5em;
    border-width: 0.25em;
}
</style>
