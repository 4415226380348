<template>
    <div class="modify-title">
        <div class="modify-title__heading detail-field-heading">
            <h2>Title Information</h2>
            <router-link
                v-if="showViewAllListingsLink"
                :to="{
                    name: 'TitleResearchCatalogListings',
                    query: { productCode },
                }"
                class="modify-title__heading-link"
            >
                View All Listings
            </router-link>
        </div>

        <div class="modify-title__row">
            <div class="modify-title__row-item">
                <h3 class="column-title-heading">
                    EAN
                </h3>
                <p class="column-title-text-detail">
                    {{ productCode }}
                </p>
            </div>
            <EditableInput
                v-model="models.description"
                class="modify-title__row-item--full"
                label="Description"
            />
        </div>

        <div class="modify-title__row">
            <EditableInput
                v-model="models.author"
                class="modify-title__row-item"
                label="Author"
            />
            <EditableInput
                v-model="models.publisher"
                class="modify-title__row-item"
                label="Publisher"
                :rules="[rules.maxLength(128)]"
            />
            <EditableInput
                v-model="models.edition"
                class="modify-title__row-item"
                label="Edition"
                type="number"
                :rules="[rules.validNumber, rules.validEdition]"
            />
        </div>

        <hr
            aria-orientation="horizontal"
            class="modify-title__divider"
            role="separator"
        >

        <div class="modify-title__row">
            <EditableInput
                v-model="models.expectedPrimarySupplierCost"
                class="modify-title__row-item"
                label="Expected Primary Supplier Cost"
                type="currency"
            />
            <EditableInput
                v-model="models.publisherListPrice"
                class="modify-title__row-item"
                label="Publisher List Price"
                type="currency"
            />
        </div>

        <!-- "Required" Fields -->
        <div class="modify-title__row">
            <div class="modify-title__row-item">
                <h3 class="column-title-heading">
                    Primary Supplier
                </h3>
                <DropdownPrimarySupplier
                    v-model="models.primarySupplier"
                    class="column-title-text-detail"
                    hideDetails
                />
            </div>
            <div class="modify-title__row-item">
                <h3 class="column-title-heading">
                    Slingshot List Price
                </h3>
                <InputCurrency
                    v-model="models.listPrice"
                    class="modify-title__row-item"
                    type="currency"
                />
            </div>
        </div>

        <!-- Designators -->
        <MultiOptionTitleDesignators
            :preSelectedList="designators"
            class="modify-title__designators"
            @changes="( changes ) => emitChanges( {
                key: 'designators',
                value: changes,
            } )"
            @removal="emitRemoval( {
                key: 'designators',
            } )"
        />

        <!-- Title Notes -->
        <div class="modify-title__notes">
            <h5 class="column-title-heading">
                <label for="notes">Title Notes</label>
            </h5>
            <v-textarea
                v-model="models.notes"
                dense
                hideDetails
                outlined
                rows="7"
            />
        </div>
    </div>
</template>
<script>
import _ from 'lodash';

// Components
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import EditableInput from '@/components/base/EditableInput';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import MultiOptionTitleDesignators from '@/components/multioption/MultiOptionTitleDesignators';

export default {
    name: 'ModifyTitle',
    components: {
        DropdownPrimarySupplier,
        EditableInput,
        InputCurrency,
        MultiOptionTitleDesignators,
    },
    props: {
        showViewAllListingsLink: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Number,
            required: true,
        },
        productCode: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        author: {
            type: String,
            default: '',
        },
        primarySupplier: {
            type: [ Object, null ],
            default: null,
        },
        listPrice: {
            type: Number,
            default: 0,
        },
        edition: {
            type: [ String, Number, null ],
            default: null,
        },
        publisher: {
            type: [ String, null ],
            default: null,
        },
        expectedPrimarySupplierCost: {
            type: [ Number, null ],
            default: null,
        },
        publisherListPrice: {
            type: [ Number, null ],
            default: null,
        },
        designators: {
            type: Array,
            default: () => ( [] ),
        },
        notes: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            /** @type { DebouncedFunc | Function } */
            modelHandlerDebounced: () => {},

            models: {
                description: this.description,
                author: this.author,
                primarySupplier: this.primarySupplier,
                listPrice: this.listPrice,
                edition: this.edition,
                publisher: this.publisher,
                expectedPrimarySupplierCost: this.expectedPrimarySupplierCost,
                publisherListPrice: this.publisherListPrice,
                notes: this.notes,
            },

            rules: {
                validNumber: (value) => {
                    if (!value) return true;
                    return !Number.isNaN(Number(value));
                },
                validEdition: (value) => {
                    if (!value) return true;
                    if (value === 0) return true;
                    // Regex pattern: 1-10 digits, no decimals, is a number
                    const pattern = /^\s*-?[0-9]{1,10}\s*$/;
                    return pattern.test(value);
                },
                maxLength: (value, length) => {
                    if (!value) return true;
                    return value.length <= length;
                },
            },
        };
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                this.resetModels();
            },
        },
        models: {
            deep: true,
            handler() {
                this.modelHandlerDebounced();
            },
        },
    },
    created() {
        // Create a debouncedFn to prevent excessive store action calls up the chain
        this.modelHandlerDebounced = _.debounce( () => { this.modelHandler(); }, 500 );
    },
    methods: {
        modelHandler() {
            Object.keys( this.models ).forEach( ( key ) => {
                if ( typeof this[ key ] === 'object' && this[ key ] !== null ) {
                    // Emit changes for objects that now have a value set
                    if ( ( this[ key ] === null ) || ( this[ key ]?.id !== this.models[ key ]?.id ) ) {
                        this.emitChanges( {
                            key,
                            value: this.models[ key ],
                        } );
                    } else {
                        this.emitRemoval( { key } );
                    }
                } else if ( this[ key ] !== this.models[ key ] ) {
                    this.emitChanges( {
                        key,
                        value: this.models[ key ],
                    } );
                } else {
                    this.emitRemoval( { key } );
                }
            } );
        },

        resetModels() {
            this.models = {
                description: this.description,
                author: this.author,
                primarySupplier: this.primarySupplier,
                listPrice: this.listPrice,
                edition: this.edition,
                publisher: this.publisher,
                expectedPrimarySupplierCost: this.expectedPrimarySupplierCost,
                publisherListPrice: this.publisherListPrice,
                notes: this.notes,
            };
        },
        /**
         * @param { Object } change
         * @property { String } key
         * @property { Any } value
         */
        emitChanges( change ) {
            this.$emit( 'changes', change );
        },
        /**
         * @param { Object } removal
         * @property { String } key
         */
        emitRemoval( removal ) {
            this.$emit( 'removal', removal );
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";

.modify-title {
    h2 {
        font-variant-caps: all-small-caps;
    }

    &__heading {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &-link {
            margin-top: .25rem;
            margin-left: 1rem;
            color: $button-blue;
            font-style: italic;
            font-size: 12px;
            text-decoration: none;
        }
    }

    &__row {
        display: flex;
        flex-flow: row nowrap;
        gap: 1rem;
        margin-top: 1rem;

        &-item {
            min-width: 32%;
            max-width: 100%;
        }
        &-item--full {
            width: 100%;
        }
    }

    &__divider {
        display: block;
        flex: 1 1 0;
        max-width: 100%;
        transition: inherit;
        margin: .7em 0;
        border: 1px solid gainsboro;
    }

    &__designators {
        width: 100%;
        margin-top: 2rem;
    }

    &__notes {
        width: 100%;
        margin-top: 2rem;
    }
}
</style>
