<template>
    <div id="checkbox-multi-select">
        <div>
            <v-row noGutters>
                <v-col cols="6">
                    <v-row
                        v-for="( item, key ) in leftColumnValues"
                        :key="key"
                        class="each-item"
                        noGutters
                        @mousedown.prevent
                    >
                        <Checkbox
                            :id="`${key}-${uid}`"
                            :value="item"
                            class="checkbox-input"
                            @change="clickHandler( key, $event )"
                        />
                        <label :for="`${key}-${uid}`">
                            {{ key }}
                        </label>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row
                        v-for="( item, key ) in rightColumnValues"
                        :key="key"
                        class="each-item"
                        noGutters
                        @mousedown.prevent
                    >
                        <Checkbox
                            :id="`${key}-${uid}`"
                            :value="item"
                            class="checkbox-input"
                            @change="clickHandler( key, $event )"
                        />
                        <label :for="`${key}-${uid}`">
                            {{ key }}
                        </label>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// Components
import Checkbox from '@/components/base/Checkbox';

let uid = 0;

/**
 * @emits 'input' => { value }
 */
export default {
    name: 'CheckboxMultiSelectPersistable',
    components: {
        Checkbox,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: () => {},
        },
        leftColumnKeys: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        // Forces uid to be a different value for each instance of the component
        // Fixes issue where duplicate ids cause labels to reference the wrong input
        // https://github.com/vuejs/vue/issues/5886
        uid += 1;
        return {
            uid: `checkbox-${ uid }`,
        };
    },
    computed: {
        leftColumnValues() {
            if ( this.leftColumnKeys.length === 0 ) {
                return this.value;
            }
            const entries = Object.fromEntries(
                Object.entries( this.value )
                    .filter(
                        ( entry ) => this.leftColumnKeys.includes( entry[ 0 ] ),
                    ),
            );
            return entries;
        },
        rightColumnValues() {
            if ( this.leftColumnKeys.length === 0 ) {
                return {};
            }
            return Object.fromEntries(
                Object.entries( this.value )
                    .filter(
                        ( entry ) => !this.leftColumnKeys.includes( entry[ 0 ] ),
                    ),
            );
        },
    },
    methods: {
        /** @param { String } item */
        clickHandler( key, value ) {
            const toReturn = { ...this.value };
            toReturn[ key ] = value;
            this.$emit( 'input', toReturn );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.each-item {
    display: flex;
    align-items: center;

    .checkbox-input {
        padding-top: 0;
        margin-top: 0;
    }

    label {
        /* this is to make sure the cursor is a pointer cross-browser */
        cursor: pointer;
        text-transform: capitalize;
        text-align: left;
    }
}
</style>
